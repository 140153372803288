<template>
  <div>
    <div
      class="toast"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
      :class="toastClass"
    >
      <div class="toast-header">
        <div style="display: inline-block; width: 50%; text-align: left;">
            <strong class="mr-auto">{{ title }}</strong>
        </div>
        <div style="display: inline-block; width: 50%; text-align: right;">
            <button
                type="button"
                class="ml-2 mb-1"
                data-dismiss="toast"
                aria-label="Close"
                @click="hideToast"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
      </div>
      <div class="toast-body">
        {{ message }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    message: String,
    show: Boolean,
  },
  computed: {
    toastClass() {
      return {
        'toast-show': this.show,
      };
    },
  },
  methods: {
    hideToast() {
      this.$emit('hide-toast');
    },
  },
};
</script>

<style scoped>
.toast-show {
  display: block;
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 1050;
}
</style>
