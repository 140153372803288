<template>
    <div>

        <!-- QUE ASÍ SE QUEDE POR EL MOMENTO; FALTA PASAR LA RUTA DEL ASSET DE TOTEM DARK LOGO A UNA VARIABLE -->

        <!-- FOOTER -->
        <div class="row app-footer">
            <div class="col d-flex align-items-center justify-content-start">
                <img src="../assets/media/png/img-totem_logo-dark.png" class="footer-logo"> &copy; 2024, Tótem Computer
            </div>
            <div class="col d-flex align-items-center justify-content-end">
                <!-- <span class="bi-question-circle-fill">&nbsp;</span><a v-bind:href="support_and_help_center" target="_blank">Centro de ayuda</a> -->
                <span class="bi-question-circle-fill">&nbsp;</span><a v-bind:href="support_and_help_center" target="_blank">Help Center</a>
            </div>
        </div>
        <!-- FOOTER END -->
        
    </div>
</template>

<script>
export default {
    name: 'Footer',
    props: {
    },
    data () {
        return {
            support_and_help_center: null,
        }
    },
    components: {
    },
    mounted () {
        
        var self = this;
        
        self.support_and_help_center = this.$parent.$parent.support_and_help_center;

    },
    methods : {
    }
}
</script>

<style scoped>

</style>
