// import Vue from 'vue'
import * as Vue from 'vue'
import { createApp } from 'vue'
import App from './App.vue'

// Vue.config.productionTip = false
// Vue.config.productionTip = true

// new Vue({
//   render: h => h(App),
// }).$mount('#app');
createApp(App).mount('#app');
