<template>
    <div>

        <!--  -->

        <!-- <video playsinline autoplay muted loop poster="polina.jpg" id="bgvid">
            <source src="../assets/media/mp4/video-blue_sky.mp4" type="video/mp4">
            <source src="../assets/media/webm/video-blue_sky.webm" type="video/webm">
        </video> -->

        <SkyBackground/>

        <!-- Modal structure -->
        <!-- <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                <h5 class="modal-title" id="myModalLabel">Modal title</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body">
                Modal body text goes here.
                </div>
                <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary">Save changes</button>
                </div>
            </div>
            </div>
        </div> -->
        <!-- Modal structure -->
        <div class="modal fade" id="myModal" tabindex="-1" aria-labelledby="myModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                <h5 class="modal-title" id="myModalLabel">Set a new password</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                <form id="passwordForm">
                    <div class="mb-3">
                    <label for="password1" class="form-label">Enter the new password</label>
                    <div class="input-group">
                        <input type="password" class="form-control" id="password1" required>
                        <button class="btn btn-outline-secondary" type="button" id="togglePassword1">
                        <i class="bi bi-eye"></i>
                        </button>
                    </div>
                    </div>
                    <div class="mb-3">
                    <label for="password2" class="form-label">Confirm the new password</label>
                    <div class="input-group">
                        <input type="password" class="form-control" id="password2" required>
                        <button class="btn btn-outline-secondary" type="button" id="togglePassword2">
                        <i class="bi bi-eye"></i>
                        </button>
                    </div>
                    </div>
                    <ul id="passwordCriteria" class="list-unstyled">
                    <li id="length" class="text-danger">At least 8 characters long</li>
                    <li id="uppercase" class="text-danger">At least one uppercase letter</li>
                    <li id="number" class="text-danger">At least one number</li>
                    <li id="symbol" class="text-danger">At least one symbol</li>
                    <li id="match" class="text-danger">Passwords must match</li>
                    </ul>
                </form>
                </div>
                <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary" id="saveChanges">Save changes</button>
                </div>
            </div>
            </div>
        </div>

        <div class="container-fluid">
            <div class="row align-items-center" style="height: 100vh;">
                <div class="col hidden-xs">
                    &nbsp;
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12">

                    <div class="text-center login-header">
                        <img src="../assets/media/png/img-totem_cloud_logo.png" width="200px" alt="Tótem Cloud PC Logo">
                        <!-- <h3 class="text-light text-shadow">Entra a tu Tótem Cloud PC</h3> -->
                        <h3 class="text-light text-shadow">Access your Tótem Cloud PC</h3>
                    </div>

                    <div class="card login-form">
                        <!-- <form action="totem_app-2-2-home.html"> -->
                        <!-- <form> -->
                            <div class="mb-3">
                                <!-- <label for="login-email" class="form-label">Correo electrónico</label> -->
                                <label for="login-email" class="form-label">Email</label>
                                <!-- v-on:keyup.enter="login()" -->
                                <input type="email" class="form-control" id="login-email" aria-describedby="emailHelp" v-model="email" v-on:keyup.enter="loginVerification()" required>
                                <p v-if="show_email_error" class="wrong_login_label" style="margin-top: 10px;">{{email_error_label}}</p>
                            </div>
                            <!-- style="display: none;" -->
                            <div id="restOfTheLoginForm" v-if="showRestOfTheForm">
                                <div class="mb-3">
                                    <!-- <label for="login-password" class="form-label">Contraseña</label> -->
                                    <label for="login-password" class="form-label">Password</label>
                                    <input type="password" class="form-control" id="login-password" v-model="password" v-on:keyup.enter="login()">
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="form-check">
                                            <p v-if="show_wrong_login_label" class="wrong_login_label">{{wrong_login_label}}</p>
                                            <input type="checkbox" class="form-check-input" id="login-remember" v-model="remember_me">
                                            <!-- <label class="form-check-label" for="login-remember">Recuérdame</label> -->
                                            <label class="form-check-label" for="login-remember">Remember me</label>
                                        </div>
                                        <!-- <span><a style="cursor: pointer; color: #0000FF;" @click="forgotMyPassword()">¿Olvidaste tu contraseña?</a></span> -->
                                        <span><a style="cursor: pointer; color: #0000FF;" @click="forgotMyPassword()">Forgot email or password?</a></span>
                                    </div>
                                    <div class="col-3">
                                        <div class="text-end">
                                            <!-- <button type="submit" class="btn btn-primary" @click="login()">Entrar</button> -->
                                            <button type="submit" class="btn btn-primary" @click="login()">Sign in</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        <!-- </form> -->
                    </div>

                    <div class="row login-site-info">
                        <div class="col-8 text-start">
                            <p class="text-light">&#169; 2023, Tótem Computer</p>
                        </div>

                        <div class="col text-end">
                            <img src="../assets/media/png/img-totem_logo.png" class="img-fluid login-logo" alt="Tótem Computer Logo">
                        </div>
                    </div>
                    <!-- <p class="text-light login-language"><span class="glyphicon bi-translate"></span> México - Español (<a class="link-light">cambiar</a>)</p> -->
                    <p class="text-light login-language"><span class="glyphicon bi-translate"></span> English (<a class="link-light">change</a>)</p>
                </div>
                <div class="col hidden-xs">
                    &nbsp;
                </div>
            </div>
        </div>
    
        <!--  -->
    </div>
</template>

<script>

import axios from 'axios'

import SkyBackground from '../components_modular/SkyBackground.vue'

export default {
    name: 'Login',
    data () {
        return {
            email: null,
            password: null,
            // wrong_login_label: 'Credenciales incorrectas, vuelve a intentar iniciar sesión.',
            wrong_login_label: 'Wrong credentials, try logging in again.',
            show_wrong_login_label: false,
            remember_me: false,
            showRestOfTheForm: false,
            show_email_error: false,
            email_error_label: null,
        }
    },
    components: {
        SkyBackground,
    },
    mounted () {

        // console.log('window.location.protocol: >' + window.location.protocol + '<');
        // if (window.location.protocol !== 'https:') window.location.href = 'https://my.totem.computer/'

        // console.log('---- ----- ----- ----- -----');
        // console.log('- - - LOGIN COMPONENT - - -');
        // console.log('---- ----- EMAIL ----- -----');
        // console.log(sessionStorage.getItem('email'));
        // console.log('---- ----- PASSWORD ----- -----');
        // console.log(sessionStorage.getItem('password'));
        // console.log('---- ----- ----- ----- -----');

        if(sessionStorage.getItem('email') !== null && sessionStorage.getItem('password') !== null){

            this.email = sessionStorage.getItem('email');
            this.password = sessionStorage.getItem('password');

            this.login();
            
        }

        // console.log('---- ----- ----- ----- -----');
        // console.log('- - - LOGIN COMPONENT - - -');
        // console.log('---- ----- TOKEN ----- -----');
        // console.log(sessionStorage.getItem('token'));
        // console.log('---- ----- ----- ----- -----');

        // let token = sessionStorage.getItem('token');

        // if(
        //     token !== undefined &&
        //     token !== 'undefined' &&
        //     token !== "undefined" &&
        //     token !== '' &&
        //     token !== "" &&
        //     token.length > 0
        // ){
        //     this.$parent.component = 'Home';
        // }

        /*
        if(
            this.$parent.token !== null &&
            this.$parent.token !== 'null' &&
            this.$parent.token !== "null" &&
            this.$parent.token !== undefined &&
            this.$parent.token !== 'undefined' &&
            this.$parent.token !== "undefined"
        ){
            this.$parent.component = 'Home';
        }else{
            console.log('No hay token de sesión activa.');
        }
        */

        document.addEventListener('DOMContentLoaded', () => {
    
    // console.log(this);
    // const self = this; // Capture 'this' in 'self'
    // console.log(self);

    const password1 = document.getElementById('password1');
    const password2 = document.getElementById('password2');
    const togglePassword1 = document.getElementById('togglePassword1');
    const togglePassword2 = document.getElementById('togglePassword2');
    const saveChanges = document.getElementById('saveChanges');
    const criteria = {
        length: document.getElementById('length'),
        uppercase: document.getElementById('uppercase'),
        number: document.getElementById('number'),
        symbol: document.getElementById('symbol'),
        match: document.getElementById('match')
    };

    function togglePasswordVisibility(input, toggleButton) {
        const type = input.getAttribute('type') === 'password' ? 'text' : 'password';
        input.setAttribute('type', type);
        toggleButton.innerHTML = type === 'password' ? '<i class="bi bi-eye"></i>' : '<i class="bi bi-eye-slash"></i>';
    }

    togglePassword1.addEventListener('click', () => togglePasswordVisibility(password1, togglePassword1));
    togglePassword2.addEventListener('click', () => togglePasswordVisibility(password2, togglePassword2));

    function validatePassword() {
        const value1 = password1.value;
        const value2 = password2.value;
        let isValid = true;

        if (value1.length >= 8) {
            criteria.length.classList.replace('text-danger', 'text-success');
        } else {
            criteria.length.classList.replace('text-success', 'text-danger');
            isValid = false;
        }

        if (/[A-Z]/.test(value1)) {
            criteria.uppercase.classList.replace('text-danger', 'text-success');
        } else {
            criteria.uppercase.classList.replace('text-success', 'text-danger');
            isValid = false;
        }

        if (/\d/.test(value1)) {
            criteria.number.classList.replace('text-danger', 'text-success');
        } else {
            criteria.number.classList.replace('text-success', 'text-danger');
            isValid = false;
        }

        if (/[^A-Za-z0-9]/.test(value1)) {
            criteria.symbol.classList.replace('text-danger', 'text-success');
        } else {
            criteria.symbol.classList.replace('text-success', 'text-danger');
            isValid = false;
        }

        if (value1 === value2) {
            criteria.match.classList.replace('text-danger', 'text-success');
        } else {
            criteria.match.classList.replace('text-success', 'text-danger');
            isValid = false;
        }

        return isValid;
    }

    password1.addEventListener('input', validatePassword);
    password2.addEventListener('input', validatePassword);

    saveChanges.addEventListener('click', () => {
        if (validatePassword()) {

            this.setNewPassword(password1.value);

        } else {
            alert('Please meet all the password criteria.');
        }
    });
});

    },
    methods: {
        setNewPassword (password) {

            console.log(password);

            
                let url = this.$parent.server_url + '/api/users/set_new_password';

                let params = {
                    email: this.email,
                    password: password,
                };

                axios.post(url, params)
                .then(response => {

                    console.log(response);
                    
                    if (response.data.sucess) {

                        alert('Password saved successfully!');
                        // Close the modal if passwords are valid
                        const modalElement = document.getElementById('myModal');
                        const modal = bootstrap.Modal.getInstance(modalElement);
                        modal.hide();

                    }
                    
                })
                .catch(function(error) {
                    console.log(error);
                })
            

        },
        forgotMyPassword () {

            window.open('https://totem.computer/my-account/edit-account/', '_blank');

        },
        loginVerification () {

            // show_email_error
            // email_error_label

            this.showRestOfTheForm = false;
            this.password = null;

            this.show_email_error = false;
            this.email_error_label = null;

            if (this.email === null) {

                // alert('Enter your email');
                this.show_email_error = true;
                this.email_error_label = 'Enter your email';

            } else {

                let url = this.$parent.server_url + '/api/users/verification';

                let params = {
                    email: this.email
                };

                axios.post(url, params)
                .then(response => {
                    console.log(response);
                    if (response.data.validation === 2) {
                        this.showRestOfTheForm = true;
                    } else if (response.data.validation === 1) {
                        console.log('ESTABLECER CONTRASEÑA');
                        
                        // $('#myModal').modal('toggle');
                        // document.getElementById('myModal').modal('toggle');

                        const modalElement = document.getElementById('myModal');
                        const modal = new bootstrap.Modal(modalElement);
                        modal.toggle();

                    } else if (response.data.validation === 0) {
                        console.log('EL USUARIO NO EXISTE EN LA BASE DE DATOS');
                        this.show_email_error = true;
                        this.email_error_label = 'Verify your email';
                    }
                })
                .catch(function(error) {
                    console.log(error);
                })

            }

        },
        login () {

            this.show_wrong_login_label = false;

            // VALIDACIONES PARA CUANDO LOS VALORES SEAN VACIOS Y EL EMAIL NO TENGA UN FORMATO ADECUADO
            // console.log(this.email)
            // console.log(this.password)
            
            let url = this.$parent.server_url + '/login';

            let params = {
                email: this.email,
                password: this.password,
            };

            axios.post(url, params)
            .then(response => {

                // console.log(response);
                // console.log(response.data.response);

                if(response.status === 200 && response.data.response === 'Unauthorized'){
                    
                    // console.log('No se ha logrado un correcto inicio de sesión');

                    this.show_wrong_login_label = true;
                    this.password = null;

                }else if(response.status === 200 && response.statusText === 'OK'){
                    
                    // console.log('Se ha logrado un correcto inicio de sesión');
                    
                    if(this.remember_me){

                        sessionStorage.setItem('token', response.data.token);
                        sessionStorage.setItem('email', this.email);
                        sessionStorage.setItem('password', this.password);

                        // console.log('---- ----- ----- ----- -----');
                        // console.log('- - - LOGIN COMPONENT - - -');
                        // console.log('---- ----- EMAIL ----- -----');
                        // console.log(sessionStorage.getItem('email'));
                        // console.log('---- ----- PASSWORD ----- -----');
                        // console.log(sessionStorage.getItem('password'));
                        // console.log('---- ----- ----- ----- -----');

                    }
                    
                    this.$parent.token = response.data.token;
                    this.$parent.name = response.data.name;
                    this.$parent.email = response.data.email;
                    this.$parent.component = 'Home';

                }

            })
            .catch(function(error) {
                console.log(error);
            })

        }
    }
}

</script>

<style scoped>

</style>
 