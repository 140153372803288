<template>
    <div>
        <!-- PINK BACKGROUND -->
        <video playsinline autoplay muted loop poster="polina.jpg" id="bgvid">
            <source src="../assets/media/mp4/video-abstract_background.mp4" type="video/mp4">
            <source src="../assets/media/video-abstract_background.webm" type="video/webm">
        </video>
        <!-- PINK BACKGROUND END -->
        
    </div>
</template>

<script>
export default {
    name: 'PinkBackground',
    props: {
    },
    data () {
        return {
        }
    },
    components: {
    },
    mounted () {
    },
    methods : {
    }
}
</script>

<style scoped>
</style>
