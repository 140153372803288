<template>
    <div>

        <!-- <video playsinline autoplay muted loop poster="polina.jpg" id="bgvid">
            <source src="../assets/media/mp4/video-abstract_background.mp4" type="video/mp4">
            <source src="../assets/media/webm/video-abstract_background.webm" type="video/webm">
        </video> -->

        <PinkBackground/>

        <!-- START MAIN CONTAINER -->
        <div class="container-fluid">

            <!-- MASTER ROW -->
            <div class="row align-items-center justify-content-center" style=" height: 100vh;">
                
                <!-- MAIN CONTENT -->
                <div class="col-lg-8 col-md-8 col-sm-12">

                    <Navbar v-if="is_mounted"></Navbar>

                    <!-- MACHINE CONTROLS AND BUTTONS -->
                    <div class="row machine-options gx-3 gy-3 pb-4">

                        <!-- DYNAMIC SINGLE MACHINE START -->
                        <div v-if="getting_machines" class="text-center">

                            <div class="spinner-border text-dark" role="status">
                                <span class="sr-only"></span>
                            </div>

                        </div>
                        <!-- <div v-else> -->
                            <div v-for="machine in machines" v-bind:key="machine.machine_id" class="col-lg-3 col-md-4 col-sm-12">

                                <div class="machine-button">
                                    <div class="machine-button-inside text-center">
                                        
                                        <a>
                                            
                                            <!-- IMAGEN A PARTIR DEL ESTADO DE LA MAQUINA -->
                                            <!-- <img v-if="machine.state === 'ready'" src="../assets/media/svg/icn-machineOn.svg" alt="Select this machine">
                                            <img v-else-if="machine.state === 'provisioning'" src="../assets/media/svg/icn-machineOn-no_logo.svg" alt="Select this machine">
                                            <img v-else-if="machine.state === 'off'" src="../assets/media/svg/icn-machineOff-Alt.svg" alt="Select this machine">
                                            <img v-else src="../assets/media/svg/icn-machineOff.svg" alt="Select this machine"> -->

                                            <img v-if="machine.state === 'ready'" src="../assets/media/svg/icn-machineOn.svg" alt="Select this machine">
                                            <img v-else-if="machine.state === 'provisioning'" src="../assets/media/svg/icn-machineOn-no_logo.svg" alt="Select this machine">
                                            <img v-else-if="machine.state === 'off'" src="../assets/media/svg/icn-machineOff.svg" alt="Select this machine">
                                            <img v-else src="../assets/media/svg/icn-machineOff-Alt.svg" alt="Select this machine">

                                            <p>{{machine.machine_alias}}</p>

                                            <!-- <div v-if="machine.state === 'ready'" class="machine-power-status"><span>Encendida</span></div>
                                            <div v-else-if="machine.state === 'provisioning'" class="machine-power-status"><span>Creando...</span></div>
                                            <div v-else-if="machine.state === 'off'" class="machine-power-status"><span>Apagada</span></div>
                                            <div v-else-if="machine.state === 'starting'" class="machine-power-status"><span>Iniciando</span></div>
                                            <div v-else-if="machine.state === 'stopping'" class="machine-power-status"><span>Apagándose</span></div>
                                            <div v-else-if="machine.state === 'restarting'" class="machine-power-status"><span>Reiniciándose</span></div>
                                            <div v-else-if="machine.state === 'serviceready'" class="machine-power-status"><span>No disponible</span></div>
                                            <div v-else-if="machine.state === 'upgrading'" class="machine-power-status"><span>Actualizándose</span></div>
                                            <div v-else class="machine-power-status"><span>Estado no disponible</span></div> -->

                                            <div v-if="machine.state === 'ready'" class="machine-power-status"><span>Powered On</span></div>
                                            <div v-else-if="machine.state === 'provisioning'" class="machine-power-status"><span>Creating...</span></div>
                                            <div v-else-if="machine.state === 'off'" class="machine-power-status"><span>Powered Off</span></div>
                                            <div v-else-if="machine.state === 'starting'" class="machine-power-status"><span>Starting</span></div>
                                            <div v-else-if="machine.state === 'stopping'" class="machine-power-status"><span>Stopping</span></div>
                                            <div v-else-if="machine.state === 'restarting'" class="machine-power-status"><span>Restarting</span></div>
                                            <div v-else-if="machine.state === 'serviceready'" class="machine-power-status"><span>Ready</span></div>
                                            <div v-else-if="machine.state === 'upgrading'" class="machine-power-status"><span>Upgrading</span></div>
                                            <div v-else class="machine-power-status"><span>Not Available</span></div>

                                        </a>

                                        <div class="dropdown">

                                            <div class="d-grid gap-2">

                                                <!-- <button class="btn btn-primary btn-sm btn-block dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" @click="selectMachineForDetails(machine.machine_id)">
                                                    Acciones
                                                </button> -->

                                                <button class="btn btn-primary btn-sm btn-block dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" @click="selectMachineForDetails(machine.machine_id)">
                                                    Actions
                                                </button>

                                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    
                                                    <!-- <li><a class="dropdown-item list-element-as-button" @click="openMachine(machine.machine_id)"><span class="bi-arrow-right-square">&nbsp; &nbsp;</span>Abrir</a></li> -->
                                                    <li><a class="dropdown-item list-element-as-button" @click="openMachine(machine.machine_id)"><span class="bi-arrow-right-square">&nbsp; &nbsp;</span>Open</a></li>
                                                    
                                                    <!-- <li><a class="dropdown-item" data-bs-toggle="modal" data-bs-target="#ModalMachineRename" href="#"><span class="bi-pencil">&nbsp; &nbsp;</span>Cambiar nombre</a></li> -->
                                                    <li><a class="dropdown-item" data-bs-toggle="modal" data-bs-target="#ModalMachineRename" href="#"><span class="bi-pencil">&nbsp; &nbsp;</span>Rename</a></li>
                                                    <!-- <li class="noselect"><a disabled class="dropdown-item"><span class="bi-pencil">&nbsp; &nbsp;</span>Cambiar nombre</a></li> -->
                                                    
                                                    <!-- <li><a class="dropdown-item list-element-as-button" data-bs-toggle="modal" data-bs-target="#ModalMachineDetails"><span class="bi-info-circle">&nbsp; &nbsp;</span>Ver detalles</a></li> -->
                                                    <li><a class="dropdown-item list-element-as-button" data-bs-toggle="modal" data-bs-target="#ModalMachineDetails"><span class="bi-info-circle">&nbsp; &nbsp;</span>See Details</a></li>
                                                    <!-- <li><a class="dropdown-item list-element-as-button" @click="showAboutThisComputerFunction()"><span class="bi-info-circle">&nbsp; &nbsp;</span>Ver detalles</a></li> -->
                                                    
                                                    <!-- <li><a class="dropdown-item" href="https://totem.computer/mi-cuenta/subscriptions/" target="_blank"><span class="bi-box-arrow-up-right">&nbsp; &nbsp;</span>Administrar</a></li> -->
                                                    <!-- <li><a class="dropdown-item" href="https://totem.computer/mi-cuenta/subscriptions/" target="_blank"><span class="bi-box-arrow-up-right">&nbsp; &nbsp;</span>Manage</a></li> -->
                                                    <li><a class="dropdown-item" href="https://totem.computer/my-account/subscriptions/" target="_blank"><span class="bi-box-arrow-up-right">&nbsp; &nbsp;</span>Manage</a></li>

                                                </ul>

                                            </div>

                                        </div>

                                    </div>

                                </div>
                                
                            </div>
                        <!-- </div> -->
                        
                    </div>
                    
                    <!-- MACHINE CONTROLS AND BUTTONS -->

                    <!-- START MODAL MACHINE DETAILS -->
                    <!-- <div v-if="showAboutThisComputer === true">
                        <AboutThisComputer :selected_machine_for_details="selected_machine_for_details"/>
                    </div> -->
                    <div class="modal fade" id="ModalMachineDetails" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" v-if="machine_selected === true">
                        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <!-- <h5 class="modal-title" id="exampleModalLabel">Acerca de esta computadora</h5> -->
                                    <h5 class="modal-title" id="exampleModalLabel">About this Cloud PC</h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="eraseMachineForDetails()"></button>
                                </div>
                                <div class="modal-body">
                                    <div class="container-fluid">
                                        <div class="row gx-5 text-break">

                                            <div class="col-4 machine-details">
                                                <img v-if="selected_machine_for_details.product_sku_data.machine_name === 'Tótem Pro Works'" src="../assets/media/png/img-totem_pro_works.png" alt="Tótem Pro Graphics" class="machine-logo">
                                                <img v-if="selected_machine_for_details.product_sku_data.machine_name === 'Tótem Pro Graphics'" src="../assets/media/png/img-totem_pro_graphics.png" alt="Tótem Pro Graphics" class="machine-logo">
                                                <!-- <p><strong>No. modelo:</strong></p> -->
                                                <p><strong>Model #:</strong></p>
                                                <p>{{selected_machine_for_details.product_sku_data.sku}}</p>
                                            </div>
                                            
                                            <div class="col-8 machine-specs">

                                                <h5>{{selected_machine_for_details.product_sku_data.machine_name}}</h5>
                                                <!-- <p>Modelo</p> -->
                                                <p>Model name</p>
                                                
                                                <h6>{{selected_machine_for_details.product_sku_data.machine_cpu}}</h6>
                                                <!-- <p>Procesador (CPU)</p> -->
                                                <p>Processor (CPU)</p>

                                                <h6>{{selected_machine_for_details.product_sku_data.machine_ram}} GB</h6>
                                                <!-- <p>Memoria (RAM)</p> -->
                                                <p>Memory (RAM)</p>

                                                <h6>{{selected_machine_for_details.product_sku_data.machine_storage}} GB</h6>
                                                <!-- <p>Almacenamiento</p> -->
                                                <p>Storage</p>

                                                <h6>{{selected_machine_for_details.product_sku_data.machine_gpu}}</h6>
                                                <!-- <p>Procesador gráfico (GPU)</p> -->
                                                <p>Graphics Processor (GPU)</p>

                                                <h6>{{selected_machine_for_details.product_sku_data.machine_os}}</h6>
                                                <!-- <p>Sistema operativo</p> -->
                                                <p>Operating System</p>

                                                <h6>{{selected_machine_for_details.product_sku_data.machine_provider}}</h6>
                                                <!-- <p>Proveedor</p> -->
                                                <p>Provider</p>

                                                <h6>{{selected_machine_for_details.product_sku_data.machine_location}}</h6>
                                                <!-- <p>Ubicación</p> -->
                                                <p>Server location</p>

                                                <!-- <h6 v-if="selected_machine_for_details.product_sku_data.plan_billing_period === 'Monthly'">Mensual</h6> -->
                                                <h6 v-if="selected_machine_for_details.product_sku_data.plan_billing_period === 'Monthly'">Monthly</h6>
                                                <h6 v-else>{{selected_machine_for_details.product_sku_data.plan_billing_period}}</h6>
                                                <!-- <p>Tipo de plan</p> -->
                                                <p>Plan Type</p>

                                                <!-- <h6>{{selected_machine_for_details.product_sku_data.plan_hours}} horas</h6> -->
                                                <h6>{{selected_machine_for_details.product_sku_data.plan_hours}} hours</h6>
                                                <!-- <p>Tiempo del plan</p> -->
                                                <p>Plan Time</p>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="eraseMachineForDetails()">Cerrar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- END MODAL MACHINE DETAILS -->

                    <Footer/>

                </div>

            </div>

        </div>

        <!-- START Modal Rename Machine -->
        <div class="modal fade" id="ModalMachineRename" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <!-- <h5 class="modal-title" id="exampleModalLabel">Cambiar nombre</h5> -->
                        <h5 class="modal-title" id="exampleModalLabel">Rename</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <!-- <form> -->
                            <div class="mb-3">
                                <!-- <label for="machine-name-edit" class="form-label">Escribe un nuevo nombre para esta Cloud PC</label> -->
                                <label for="machine-name-edit" class="form-label">Choose a new name for this Cloud PC</label>
                                <input type="text" class="form-control" aria-describedby="textHelp" v-model="machine_alias_for_updating">
                                <!-- <div v-if="show_wrong_machine_alias_change" class="wrong_machine_alias_change" style="color: red;">{{wrong_machine_alias_change}}</div> -->
                                <div v-if="machine_alias_for_updating.length <= 0" class="wrong_machine_alias_change" style="color: red;">{{wrong_machine_alias_change}}</div>
                            </div>
                        <!-- </form> -->
                    </div>
                    <div class="modal-footer">
                        <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button> -->
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <!-- <button :disabled='machine_alias_for_updating.length <= 0' type="button" class="btn btn-primary" @click="changeMachineNameAlias(selected_machine_for_details.machine_id, machine_alias_for_updating)" data-bs-dismiss="modal">Guardar</button> --> <!-- data-bs-dismiss="modal" -->
                        <button :disabled='machine_alias_for_updating.length <= 0' type="button" class="btn btn-primary" @click="changeMachineNameAlias(selected_machine_for_details.machine_id, machine_alias_for_updating)" data-bs-dismiss="modal">OK</button> <!-- data-bs-dismiss="modal" -->
                    </div>
                </div>
            </div>
        </div>
        <!-- END Modal Machine Details -->

  </div>
</template>

<script>

import axios from 'axios'

import Navbar from '../components_modular/Navbar.vue'
import Footer from '../components_modular/Footer.vue'
import PinkBackground from '../components_modular/PinkBackground.vue'
// import AboutThisComputer from '../components_modular/AboutThisComputer.vue'


export default {
    name: 'Home',
    props: {
    },
    data () {
        return {
            
            name: null,
            email: null,
            
            machines: [],
            machines_amount: 0,
            
            selected_machine_for_details: null,
            machine_selected: false,

            is_mounted: false,

            getting_machines: true,
            
            showAboutThisComputer: false,

            show_wrong_machine_alias_change: false,
            
            // wrong_machine_alias_change: 'El nombre no puede estar vacío.',
            wrong_machine_alias_change: 'The machine name cannot be empty.',
            
            machine_alias_for_updating: '',

        }
    },
    components: {
        Navbar,
        Footer,
        PinkBackground,
        // AboutThisComputer,
    },
    mounted () {
        this.name = this.$parent.name;
        this.email = this.$parent.email;
        this.getUserMachinesAmount();
        this.getUserMachines();
        // window.setInterval(() => {
        //     // this.getUserMachines();
        //     this.getUserMachines();
        // }, 5000);
    },
    methods : {

        changeMachineNameAlias(machineId, machineAlias){

            console.log('machineAlias: >' + machineAlias + '<');
            console.log('machineAlias: >' + machineAlias.length + '<');

            if(
                machineAlias === '' ||
                machineAlias === "" ||
                machineAlias.length === 0
            ){
                
                this.show_wrong_machine_alias_change = true;

            }else{

                this.show_wrong_machine_alias_change = false;

                // console.log(machineId);
                // console.log(machineAlias);
                
                let url = this.$parent.server_url + '/api/own/' + machineId + '/change_machine_name/' + machineAlias;

                axios.get(url)
                .then(response => {

                    // console.log(response);
                    console.log(response.data);

                    this.machine_alias_for_updating = '';

                    this.getUserMachines();

                })
                .catch(function(error) {
                    console.log(error);
                })

            }

        },

        showAboutThisComputerFunction () {

            if(this.showAboutThisComputer === false){

                this.showAboutThisComputer = true;

            }else if(this.showAboutThisComputer === true){

                this.showAboutThisComputer = false;

            }

        },

        getUserMachinesAmount () {

            let url = this.$parent.server_url + '/users/' + this.email + '/machines/amount';

            axios.get(url)
            .then(response => {

                this.machines_amount = response.data.machines_amount;

                this.is_mounted = true
                
            })
            .catch(function(error) {
                console.log(error);
            })

        },

        getUserMachines () {

            this.machines =  [];

            let url = this.$parent.server_url + '/users/' + this.email + '/machines';

            axios.get(url)
            .then(response => {

                for(let i = 0; i < response.data.machines.length; i +=1){

                    let url2 = this.$parent.server_url + '/api/own/' + response.data.machines[i].machine_id + '/get_machine_product_sku_data'

                    axios.get(url2)
                    .then(response2 => {

                        response.data.machines[i]['product_sku_data'] = response2.data.product_sku_data;

                        let url3 = this.$parent.server_url + '/api/own/' + response.data.machines[i].machine_id + '/get_machine_live_state'

                        axios.get(url3)
                        .then(response3 => {

                            response.data.machines[i].state = response3.data.machine_state;

                            this.machines.push(response.data.machines[i]);

                        })
                        .catch(function(error) {
                            console.log(error);
                        })

                    })
                    .catch(function(error) {
                        console.log(error);
                    })

                    if(i === response.data.machines.length - 1){
                        this.getting_machines = false;
                    }

                }
                
                // console.log(response.data.machines);

                // this.machines = response.data.machines;

                this.getting_machines = false;

            })
            .catch(function(error) {
                console.log(error);
            })

        },

        openMachine(machine_id){

            this.$parent.machine_id = machine_id;

            this.$parent.component = 'Machine';

        },

        selectMachineForDetails(machine_id){

            // console.log(machine_id);

            for(let i = 0; i < this.machines.length; i += 1){

                if(machine_id === this.machines[i].machine_id){

                    this.selected_machine_for_details = this.machines[i];

                    this.machine_alias_for_updating = this.selected_machine_for_details.machine_alias;

                    break;

                }

            }

            // console.log(this.selected_machine_for_details);

            this.machine_selected = true;

        },

        eraseMachineForDetails(){

            this.machine_selected = false;
            this.selected_machine_for_details = null;

        },

    }
}
</script>

<style scoped>
</style>
