<template>
    <div>
        
        <!-- TOP BAR -->
        <div class="row top-bar d-flex align-items-center">

            <!-- LEFT SIDE: BACK BUTTON, PC NAME, AND STATUS -->
            <div v-if="parent_component == 'Home'" class="col-6 top-bar-intro d-flex align-items-center justify-content-start">
                <!-- PC NAME AND PC STATUS -->
                <div class="col-auto top-bar-greetings">
                    <!-- <h3>Hola, {{name}}!</h3> -->
                    <h3>Hello, {{name}}!</h3>
                    <!-- <p v-if="machines_amount > 1">Tienes {{machines_amount}} computadoras en la nube:</p> -->
                    <p v-if="machines_amount > 1">You have {{machines_amount}} Cloud PCs:</p>
                    <p v-else-if="machines_amount == 1">You have 1 Cloud PC:</p>
                    <p v-else>You have no cloud PCs</p>
                </div>
            </div>
            <!-- LEFT SIDE: BACK BUTTON, PC NAME, AND STATUS -->
            <div v-else-if="parent_component == 'Machine'" class="col-6 top-bar-intro d-flex align-items-center justify-content-start">
                <!-- BACK BUTTON -->
                <div class="col-auto top-bar-back">
                    <!-- <a href="totem_app-2-2-home.html"><button type="button" class="btn btn-primary"><span class="bi-caret-left-fill">&nbsp; &nbsp;</span>Regresar</button></a> -->
                    
                    <!-- <div v-if="disable_back_button"><a><button type="button" class="btn btn-secondary" disabled><span class="bi-caret-left-fill">&nbsp; &nbsp;</span>Regresar</button></a></div>
                    <div v-if="!disable_back_button"><a @click="goBackToHome()"><button type="button" class="btn btn-primary"><span class="bi-caret-left-fill">&nbsp; &nbsp;</span>Regresar</button></a></div> -->

                    <div v-if="disable_back_button"><a><button type="button" class="btn btn-secondary" disabled><span class="bi-caret-left-fill">&nbsp; &nbsp;</span>Go back</button></a></div>
                    <div v-if="!disable_back_button"><a @click="goBackToHome()"><button type="button" class="btn btn-primary"><span class="bi-caret-left-fill">&nbsp; &nbsp;</span>Go back</button></a></div>

                </div>
                <!-- PC NAME AND PC STATUS -->
                <div class="col-auto top-bar-greetings">
                    <!-- <h3>{{machine.name}} <a data-bs-toggle="modal" data-bs-target="#ModalMachineRename"><span class="bi bi-pencil"></span></a>&nbsp;<a href="#" data-bs-toggle="modal" data-bs-target="#ModalMachineDetails"><span class="bi bi-info-circle-fill"></span></a></h3> -->
                    <h3>{{machine.machine_alias}} 
                        <a data-bs-toggle="modal" data-bs-target="#ModalMachineRename"><span class="bi bi-pencil"></span></a>&nbsp;<a data-bs-toggle="modal" data-bs-target="#ModalMachineDetails2"><span class="bi bi-info-circle-fill"></span></a>
                    </h3>
                    <!-- <h3>{{machine.machine_alias}} <span class="bi bi-pencil"></span><a data-bs-toggle="modal"></a>&nbsp;<a data-bs-toggle="modal" data-bs-target="#ModalMachineDetails"><span class="bi bi-info-circle-fill"></span></a></h3> -->
                    <p>
                        <!-- <span v-if=" machine.gpu === 'GRID K160Q (2GB)'">Tótem Pro Works</span>
                        <span v-else-if=" machine.gpu === 'Quadro RTX4000'">Tótem Pro Graphics</span>
                        <span v-else>Modelo no disponible</span> -->
                        
                        <!-- <span>{{product_sku_data.machine_name}}</span>
                            | 
                        <span v-if="machine_state === 'off'" class="bi-dash-circle-fill status-off">&nbsp; Apagada</span>
                        <span v-else-if="machine_state === 'starting'" class="bi-record-circle-fill status-off">&nbsp; Iniciando</span>
                        <span v-else-if="machine_state === 'ready'" class="bi-check-circle-fill status-on">&nbsp; Lista</span>
                        <span v-else-if="machine_state === 'serviceready'" class="bi-check-circle-fill status-on">&nbsp; Lista</span>
                        <span v-else-if="machine_state === 'provisioning'" class="bi-record-circle-fill status-off">&nbsp; Creando...</span>
                        <span v-else-if="machine_state === 'stopping'" class="bi-record-circle-fill status-off">&nbsp; Apagándose</span>
                        <span v-else-if="machine_state === 'restarting'" class="bi-record-circle-fill status-off">&nbsp; Reiniciándose</span>
                        <span v-else-if="machine_state === 'upgrading'" class="bi-record-circle-fill status-off">&nbsp; Actualizándose</span>
                        <span v-else class="bi-record-circle-fill status-off">&nbsp; Estado no reconocido</span> -->

                        <span>{{product_sku_data.machine_name}}</span>
                            | 
                        <span style="background-color: #212529; border-radius: 12.5px; padding: 5px;" v-if="avail_time_0 === '0' && avail_time_1 === '00'" class="status-off"> ⚠️&nbsp;No remaining hours</span>
                        <span style="background-color: #212529; border-radius: 12.5px; padding: 5px;" v-else-if="machine_state === 'off'" class="bi-dash-circle-fill status-off">&nbsp; Powered Off</span>
                        <span style="background-color: #212529; border-radius: 12.5px; padding: 5px;" v-else-if="machine_state === 'starting'" class="bi-record-circle-fill status-on">&nbsp; Starting</span>
                        <!-- <span v-else-if="machine_state === 'ready'" class="bi-check-circle-fill status-on">&nbsp; Powered On</span> -->
                        <span style="background-color: #212529; border-radius: 12.5px; padding: 5px;" v-else-if="machine_state === 'ready'" class="bi-check-circle-fill status-on">&nbsp; Ready</span>
                        <span style="background-color: #212529; border-radius: 12.5px; padding: 5px;" v-else-if="machine_state === 'serviceready'" class="bi-check-circle-fill status-on">&nbsp; Ready</span>
                        <span style="background-color: #212529; border-radius: 12.5px; padding: 5px;" v-else-if="machine_state === 'provisioning'" class="bi-record-circle-fill status-on">&nbsp; Creating...</span>
                        <span style="background-color: #212529; border-radius: 12.5px; padding: 5px;" v-else-if="machine_state === 'stopping'" class="bi-record-circle-fill status-on">&nbsp; Stopping</span>
                        <span style="background-color: #212529; border-radius: 12.5px; padding: 5px;" v-else-if="machine_state === 'restarting'" class="bi-record-circle-fill status-on">&nbsp; Restarting</span>
                        <span style="background-color: #212529; border-radius: 12.5px; padding: 5px;" v-else-if="machine_state === 'upgrading'" class="bi-record-circle-fill status-on">&nbsp; Upgrading</span>
                        <!-- <span v-else class="bi-record-circle-fill status-off">&nbsp; Not Available</span> -->
                        <span style="background-color: #212529; border-radius: 12.5px; padding: 5px;" v-else class="bi-record-circle-fill status-off">&nbsp; Waiting for Cloud PC</span>

                    </p>
                </div>
            </div>
            <div v-else class="col-6 top-bar-intro d-flex align-items-center justify-content-start">
                <!-- Sin componente reconocido por el momento -->
            </div>
            <!-- END OF LEFT SIDE -->

            <!-- RIGHT SIDE: CONNECTION QUALITY AND ACCOUNT OPTIONS -->
            <div class="col-6 top-bar-actions">
                <div class="row d-flex justify-content-end">
                    <!-- ACCOUNT OPTIONS -->
                    <div class="col-auto top-bar-account">
                        <div class="dropdown dropdown-menu-right text-end">
                            <!-- <span v-if="parent_component == 'Machine'" class="bi-reception-3">Calidad de conexión <a data-bs-toggle="modal" data-bs-target="#ModalConnectionQuality"><span class="bi-info-circle-fill info-icon"></span></a></span> -->
                            <!-- <span v-if="parent_component == 'Machine'" class="bi-reception-3">Connection Quality <a data-bs-toggle="modal" data-bs-target="#ModalConnectionQuality"><span class="bi-info-circle-fill info-icon"></span></a></span> -->
                            &nbsp;
                            <button class="btn btn-primary dropdown-toggle" type="button"
                                id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                <span class="bi-person-circle">&nbsp;</span>{{name}}
                            </button>
                            <ul class="dropdown-menu dropdown-menu-end text-end" aria-labelledby="dropdownMenuButton1">
                                <!-- <li><a class="dropdown-item" v-bind:href="my_account" target="_blank">Mi cuenta <span class="bi-box-arrow-up-right"></span></a></li> -->
                                <li><a class="dropdown-item" v-bind:href="my_account" target="_blank">My account <span class="bi-box-arrow-up-right"></span></a></li>
                                <!-- <li><a class="dropdown-item" v-bind:href="my_subscriptions" target="_blank">Mis suscripciones <span class="bi-box-arrow-up-right"></span></a></li> -->
                                <li><a class="dropdown-item" v-bind:href="my_subscriptions" target="_blank">My subscriptions <span class="bi-box-arrow-up-right"></span></a></li>
                                <!-- <li class="list-element-as-button"><a @click="closeSession()" class="dropdown-item dropdown-danger">Cerrar sesión <span class="bi-x-circle-fill"></span></a></li> -->
                                <li class="list-element-as-button"><a @click="closeSession()" class="dropdown-item dropdown-danger">Log out <span class="bi-x-circle-fill"></span></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <!-- END OF RIGHT SIDE -->

        </div>
        <!-- END TOP BAR -->

    </div>
</template>

<script>
import axios from 'axios'
export default {
    name: 'Navbar',
    data () {
        return {
            
            // Home Component
            machines_amount: null,

            // Home and Machine components
            name: null,
            my_account: null,
            my_subscriptions: null,
            
            // For internal Navbar component use
            parent_component: null,

            // Machine component
            machine: null,

            machine_state: null,

            disable_back_button: false,
            
            product_sku_data: {},

            avail_time_0: '',
            avail_time_1: '',

        }
    },
    components: {
    },
    mounted () {

        var self = this;

        // self.machines_amount = this.$parent.$parent.machines_amount;
        self.machines_amount = this.$parent.machines_amount;
        
        self.name = this.$parent.$parent.name;
        self.my_account = this.$parent.$parent.my_account;
        self.my_subscriptions = this.$parent.$parent.my_subscriptions;

        self.parent_component = this.$parent.$parent.component;

        if(self.parent_component === 'Machine'){
            self.machine = this.$parent.machine;
            // self.machine_state = self.machine.state;
            self.machine_state = this.$parent.machine.state;
            // self.disable_back_button = this.$parent.disable_back_button;
            self.product_sku_data = this.$parent.product_sku_data;
        }

        console.log('MACHINE!!!');
        console.log(self.machine);

        window.setInterval(() => {
            // console.log('1');
            if(self.parent_component === 'Machine'){

                // console.log('[NAVBAR] disable_back_button: ' + self.disable_back_button);
                // console.log('2');
                
                // this.getMachineLiveState();

                self.machine = this.$parent.machine;
                self.machine_state = this.$parent.machine.state;
                // self.disable_back_button = this.$parent.disable_back_button;

                this.avail_time_0 =  this.$parent.machine.avail_time_0;
                this.avail_time_1 =  this.$parent.machine.avail_time_1;

                // console.log('this.avail_time_0 => ' + this.avail_time_0);
                // console.log('this.avail_time_1 => ' + this.avail_time_1);

            }else{
                // console.log('3');
            }
            // console.log('4');
        }, 1000);

    },
    methods : {

        closeSession () {

            this.$parent.$parent.token = null;
            this.$parent.$parent.name = null;
            this.$parent.$parent.email = null;
            this.$parent.$parent.machine_id = null;
            // this.$parent.$parent.machines_amount = 0;
            // this.$parent.$parent.machines = null;
            // this.$parent.$parent.machine = null;

            sessionStorage.removeItem("email");
            sessionStorage.removeItem("password");

            this.$parent.$parent.component = 'Login';

        },

        goBackToHome(){
            
            this.$parent.$parent.component = 'Home';

        },

        getMachineLiveState(){

            let machine_id = this.machine.machine_id;
            
            let url = this.$parent.$parent.server_url + '/api/own/' + machine_id + '/get_machine_live_state'

            axios.get(url)
            .then(response => {

                // console.log(response.data);

                this.machine_state = response.data.machine_state;

                // console.log(this.machine_state);

            })
            .catch(function(error) {
                console.log(error);
            })

        },


    }
}
</script>

<style scoped>
</style>
