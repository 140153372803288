<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <!-- <Login/> -->
    <!-- <Home/> -->
    <!-- <Machines/> -->
    <component v-bind:is="component"></component>
    <!-- <Sockets/> -->
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import Login from './components/Login.vue'
import Home from './components/Home.vue'
import Machine from './components/Machine.vue'
// import Sockets from './components/Sockets.vue'

export default {
  name: 'App',
  data() {
    return {
      // component: 'Login',
      component: 'Login',

      server_url: 'https://api.totem.computer', // ESTO ES PARA CUANDO SE TRABAJA SOBRE LA API MONTADA EN HEROKU
      // server_url: 'http://localhost:8081', // ESTO ES PARA TRABAJAR CON LA API LOCALIZADA EN LOCALHOST
      
      // server_url: 'http://api.totem.computer', // ESTO NO SE USA

      token: null,
      name: null,
      email: null,
      password: null,
      
      my_account: 'https://totem.computer/my-account/',
      my_subscriptions: 'https://totem.computer/my-account/subscriptions/',
      // unique_subscription: 'https://totem.computer/my-account/view-subscription/2405/',
      support_and_help_center: 'https://totem.computer/support',
      user_manual:'https://totem.computer/support/user-manual',
      // renew_early:'https://totem.computer/my-account/?subscription_renewal_early=2358&subscription_renewal=true',

      // machines_amount: 0,
      // machine: null,
      machine_id: null,

    }
  },
  components: {
    // HelloWorld,
    Login,
    Home,
    Machine,
    // ComponentA,
    // ComponentB,
    // ComponentC,
    // Sockets,
  }
}
</script>

<style>
/*
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css");
html, body {
  font-family: Avenir, Helvetica, Arial, sans-serif;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
*/
</style>
