<template>
    <div>

        <!-- <div>machine.state: {{machine.state}}</div> -->
        <!-- <div>disable_back_button: {{disable_back_button}}</div> -->

        <!-- VIDEO DE FONDO -->
        <!-- <video playsinline autoplay muted loop poster="polina.jpg" id="bgvid">
            <source src="../assets/media/mp4/video-abstract_background.mp4" type="video/mp4">
            <source src="../assets/media/video-abstract_background.webm" type="video/webm">
        </video> -->
        <PinkBackground/>

        <!-- START MAIN CONTAINER -->
        <div class="container-fluid">

            <!-- MASTER ROW -->
            <div class="row align-items-center justify-content-center" style=" height: 100vh;">

                <!-- MAIN CONTENT -->
                <div class="col-lg-8 col-md-8 col-sm-12">

                    <!-- <Navbar v-if="is_mounted_navbar"></Navbar> -->
                    <Navbar v-if="is_mounted_navbar && is_mounted"></Navbar>

                    <!-- <button @click="showToast">Show Toast</button> -->

                    <Toast
                        v-if="toastVisible"
                        :title="toastTitle"
                        :message="toastMessage"
                        :show="toastVisible"
                        @hide-toast="hideToast"
                    />

                    <!-- <p>{{machine.state}}</p> -->

                    <!-- MACHINE CONTROLS AND BUTTONS -->
                    <div class="row machine-options gx-3 gy-3 justify-content-center">

                        <!-- POWER BUTTON -->
                        
                        <!-- OFF -->
                        <!-- STARTING || STOPPING -->
                        <!-- SERVICEREADY || READY -->
                        <!-- PROVISIONING || RESTARTING || UPGRADING || ... -->
                        <div class="col-lg-3 col-md-4 col-sm-12">

                            <!-- 0 -->
                            <div class="machine-button action-button action-button-disabled" v-if="deactivate_machine_controls">
                                <div class="machine-button-inside text-center machine-control-btn">
                                    <a>
                                        <img src="../assets/media/svg/icn-power.svg" alt="Encender equipo" class="temporal-label">
                                        <!--
                                        <p v-if="machine.state === 'off'">Powered Off</p>
                                        <p v-else-if="machine.state === 'starting'">Starting</p>
                                        <p v-else-if="machine.state === 'ready'">Powered On</p>
                                        <p v-else-if="machine.state === 'serviceready'">Ready</p>
                                        <p v-else-if="machine.state === 'provisioning'">Creating...</p>
                                        <p v-else-if="machine.state === 'stopping'">Stopping</p>
                                        <p v-else-if="machine.state === 'restarting'">Restarting</p>
                                        <p v-else-if="machine.state === 'upgrading'">Upgrading</p>
                                        <p v-else>Not Available</p>
                                        -->
                                        <p>Power On</p>
                                        <!-- <p>0</p> -->
                                    </a>
                                </div>
                            </div>
                            <!-- 1 -->
                            <div class="machine-button action-button" v-else-if="(is_first_button_pressed_to_on === false && is_first_button_pressed_to_off === false) && (machine.state === 'off' || machine.state === 'serviceready' || machine.state === 'ready')">
                                <div class="machine-button-inside text-center machine-control-btn">
                                    <a v-if="machine.state === 'off'" @click="turnOnMachine()">
                                        <img src="../assets/media/svg/icn-power.svg" alt="Encender equipo" class="temporal-label">
                                        <!-- <p v-if="machine.state === 'off'">Encender</p> -->
                                        <p v-if="machine.state === 'off'">Power On</p>
                                        <!-- <p>1.1</p> -->
                                    </a>
                                    <a v-else-if="machine.state === 'serviceready' || machine.state === 'ready'" data-bs-toggle="modal" data-bs-target="#ModalMachinePower" id="turnOffMachineATag">
                                        <img src="../assets/media/svg/icn-power.svg" alt="Encender equipo" class="temporal-label">
                                        <!-- <p v-if="machine.state === 'ready'">Apagar</p> -->
                                        <p v-if="machine.state === 'ready'">Power Off</p>
                                        <!-- <p v-else-if="machine.state === 'serviceready'">Apagar</p> -->
                                        <p v-else-if="machine.state === 'serviceready'">Power Off</p>
                                        <!-- <p>1.2</p> -->
                                    </a>
                                </div>
                            </div>
                            <!-- 2 -->
                            <!-- <div class="machine-button action-button action-button-disabled" v-if="(is_first_button_pressed_to_on === false || is_first_button_pressed_to_off === false) && !(machine.state === 'off' || machine.state === 'serviceready' || machine.state === 'ready')"> -->
                            <!-- <div class="machine-button action-button action-button-disabled" v-else-if="(is_first_button_pressed_to_on === true || is_first_button_pressed_to_off === true) && !(machine.state === 'off' || machine.state === 'serviceready' || machine.state === 'ready')"> -->
                            <div class="machine-button action-button action-button-disabled" v-else-if="is_first_button_pressed_to_on === true || is_first_button_pressed_to_off === true">
                                <div class="machine-button-inside text-center machine-control-btn">
                                    <a>
                                        <img src="../assets/media/gif/loader.gif">
                                        
                                        <!-- <p v-if="machine.state === 'off'">Apagada</p>
                                        <p v-else-if="machine.state === 'starting'">Iniciando</p>
                                        <p v-else-if="machine.state === 'ready'">Lista</p>
                                        <p v-else-if="machine.state === 'serviceready'">Lista</p>
                                        <p v-else-if="machine.state === 'provisioning'">Creando...</p>
                                        <p v-else-if="machine.state === 'stopping'">Apagándose</p>
                                        <p v-else-if="machine.state === 'restarting'">Reiniciándose</p>
                                        <p v-else-if="machine.state === 'upgrading'">Actualizándose</p>
                                        <p v-else>Estado no reconocido</p> -->
                                        
                                        <!--
                                        <p v-if="machine.state === 'off'">Powered Off</p>
                                        <p v-else-if="machine.state === 'starting'">Starting</p>
                                        <p v-else-if="machine.state === 'ready'">Powered On</p>
                                        <p v-else-if="machine.state === 'serviceready'">Ready</p>
                                        <p v-else-if="machine.state === 'provisioning'">Creating...</p>
                                        <p v-else-if="machine.state === 'stopping'">Stopping</p>
                                        <p v-else-if="machine.state === 'restarting'">Restarting</p>
                                        <p v-else-if="machine.state === 'upgrading'">Upgrading</p>
                                        <p v-else>Not Available</p>
                                        -->

                                        <!-- <p>2</p> -->
                                        <!-- <p>Fulfilling</p> -->
                                        <p>Please wait...</p>

                                    </a>
                                </div>
                            </div>
                            <!-- 3 -->
                            <div class="machine-button action-button action-button-disabled" v-else-if="(is_first_button_pressed_to_on === false || is_first_button_pressed_to_off === false) && (machine.state !== 'off' || machine.state !== 'serviceready' || machine.state !== 'ready')">
                                <div class="machine-button-inside text-center machine-control-btn">
                                    <a>
                                        <img src="../assets/media/gif/loader.gif">
                                        
                                        <!-- <p v-if="machine.state === 'off'">Apagada</p>
                                        <p v-else-if="machine.state === 'starting'">Iniciando</p>
                                        <p v-else-if="machine.state === 'ready'">Lista</p>
                                        <p v-else-if="machine.state === 'serviceready'">Lista</p>
                                        <p v-else-if="machine.state === 'provisioning'">Creando...</p>
                                        <p v-else-if="machine.state === 'stopping'">Apagándose</p>
                                        <p v-else-if="machine.state === 'restarting'">Reiniciándose</p>
                                        <p v-else-if="machine.state === 'upgrading'">Actualizándose</p>
                                        <p v-else>Estado no reconocido</p> -->

                                        <!--
                                        <p v-if="machine.state === 'off'">Powered Off</p>
                                        <p v-else-if="machine.state === 'starting'">Starting</p>
                                        <p v-else-if="machine.state === 'ready'">Powered On</p>
                                        <p v-else-if="machine.state === 'serviceready'">Ready</p>
                                        <p v-else-if="machine.state === 'provisioning'">Creating...</p>
                                        <p v-else-if="machine.state === 'stopping'">Stopping</p>
                                        <p v-else-if="machine.state === 'restarting'">Restarting</p>
                                        <p v-else-if="machine.state === 'upgrading'">Upgrading</p>
                                        <p v-else>Not Available</p>
                                        -->

                                        <!-- <p>3<p></p></p> -->
                                        <!-- <p>Fulfilling</p> -->
                                        <p>Please wait...</p>

                                    </a>
                                </div>
                            </div>

                            <!--
                            <div class="auto-shutdown-box text-end">
                                <div class="form-check form-switch">
                                    <input checked disabled class="form-check-input" type="checkbox" id="flexSwitchCheckDefault">
                                    <label class="form-check-label" for="flexSwitchCheckDefault">Auto-Shutdown</label>
                                </div>
                            </div>
                            -->

                        </div>
                        <!-- POWER BUTTON END -->

                        <!-- CONNECT BUTTON -->
                        <div class="col-lg-3 col-md-4 col-sm-12">
                            <div v-if="deactivate_machine_controls" class="machine-button action-button action-button-disabled">
                                <div class="machine-button-inside text-center">
                                    <a>
                                        <img src="../assets/media/svg/icn-connect.svg" alt="Encender equipo" class="temporal-label">
                                        <!-- <p class="temporal-label">Conectar</p> -->
                                        <p class="temporal-label">Connect</p>
                                    </a>
                                </div>
                            </div>
                            <div v-else-if="machine.state === 'serviceready' || machine.state === 'ready'" class="machine-button action-button" id="secondMachineControlButton">
                                <div class="machine-button-inside text-center">
                                    <!-- ENGLISH -->
                                    <!-- <a :href="'anydesk:' + any_desk" target="_blank" id="linkForConnectingToAnyDesk">
                                        <img src="../assets/media/svg/icn-connect.svg" alt="Encender equipo" class="temporal-label">
                                        <p class="temporal-label">Connect</p>
                                    </a> -->
                                    
                                    <!-- GUACAMOLE VERSION -->
                                    <a :href="any_desk" target="_blank" id="linkForConnectingToAnyDesk">
                                        <img src="../assets/media/svg/icn-connect.svg" alt="Encender equipo" class="temporal-label">
                                        <p class="temporal-label">Connect</p>
                                    </a>

                                    <!-- SPANISH -->
                                    <!-- <a :href="any_desk" rel="noopener noreferrer" target="_blank" download>
                                        <img src="../assets/media/svg/icn-connect.svg" alt="Encender equipo" class="temporal-label">
                                        <p class="temporal-label">Conectar</p>
                                    </a> -->
                                </div>
                            </div>
                            <div v-else class="machine-button action-button action-button-disabled">
                                <div class="machine-button-inside text-center">
                                    <a>
                                        <img src="../assets/media/svg/icn-connect.svg" alt="Encender equipo" class="temporal-label">
                                        <!-- <p class="temporal-label">Conectar</p> -->
                                        <p class="temporal-label">Connect</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <!-- CONNECT BUTTON END -->

                        <!-- MACHINE SETTINGS BUTTON -->
                        <div class="col-lg-3 col-md-4 col-sm-12">
                            <div class="machine-button action-button">
                                <div class="machine-button-inside text-center">
                                    <a href="#" data-bs-toggle="offcanvas" data-bs-target="#offcanvasSettings"
                                        aria-controls="offcanvasRight">
                                        <img src="../assets/media/svg/icn-options.svg" alt="Encender equipo">
                                        <!-- <p>Ajustes</p> -->
                                        <p>Options</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <!-- MACHINE SETTINGS BUTTON END -->

                    </div>
                    <!-- END MACHINE CONTROLS AND BUTTONS -->

                    <!-- SUBSCRIPTION STATUS ROW -->
                    <div class="row app-subscription-row d-flex align-items-center justify-content-end">
                        <div class="col session-time">
                            <h5><span class="bi-stopwatch">&nbsp;</span>{{session_hours}}h {{session_minutes}}m</h5>
                            <!-- <p>Tiempo transcurrido en la sesión</p> -->
                            <!-- <p>Time elapsed this session</p> -->
                            <p>This Session Time</p>
                            <!-- <p>Tiempo total transcurrido</p> -->
                        </div>
                        <div class="col-auto time-remaining">
                            <!-- PENDIENTE -->
                            <!-- <h5><span class="bi-clock-history">&nbsp;</span>{{remaining_hours}} h {{remaining_minutes}} m</h5> -->
                            <!-- <h5><span class="bi-clock-history">&nbsp;</span>{{remaining_hours}}h {{remaining_minutes}}m</h5> -->
                            <!-- {{machine.avail_time}} -->
                            <h5><span class="bi-clock-history">&nbsp;</span><span v-if="negative_hours">-</span>{{machine.avail_time_0}}h {{machine.avail_time_1}}m</h5>
                            <!-- <p>Tiempo disponible</p> -->
                            <p>Remaining use time</p>
                        </div>
                        <div class="col-auto">
                            <!-- <button v-on:click="renewSubscription()" type="button" class="btn btn-secondary btn-sm" data-toggle="tooltip" data-placement="top" title="Renew your subscription"><span class="bi-arrow-clockwise">&nbsp;</span>Renovar</button>&nbsp;
                            <button type="button" class="btn btn-secondary btn-sm disabled" data-toggle="tooltip" data-placement="top" title="Tooltip on top"><span class="bi-cloud-plus-fill">&nbsp;</span>Recargar</button> -->
                            <button v-on:click="renewSubscription()" type="button" class="btn btn-secondary btn-sm" data-toggle="tooltip" data-placement="top" title="Renew your subscription"><span class="bi-arrow-clockwise">&nbsp;</span>Renew</button>&nbsp;
                            <button type="button" class="btn btn-secondary btn-sm disabled" data-toggle="tooltip" data-placement="top" title="Tooltip on top"><span class="bi-cloud-plus-fill">&nbsp;</span>Add Hours</button>
                        </div>
                    </div>
                    <!-- END SUBSCRIPTION STATUS ROW -->

                    <Footer/>

                </div>
                <!-- END MAIN CONTENT ROW -->

            </div>
            <!-- END MASTER ROW -->

        </div>
        <!-- END MAIN CONTAINER -->

        <!-- AVAILABLE MODALS FOR THIS VIEW: -->

        <!-- START Modal Machine Details -->
        
        <div class="modal fade" id="ModalMachineDetails2" tabindex="-1" aria-labelledby="exampleModalLabel2" aria-hidden="true" v-if="is_mounted === true">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <!-- <h5 class="modal-title" id="exampleModalLabel2">Acerca de esta computadora</h5> -->
                        <h5 class="modal-title" id="exampleModalLabel2">About this Cloud PC</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="eraseMachineForDetails()"></button>
                    </div>
                    <div class="modal-body">
                        <div class="container-fluid">
                            <div class="row gx-5 text-break">

                                <div class="col-4 machine-details">
                                    <img v-if="product_sku_data.machine_name === 'Tótem Pro Works'" src="../assets/media/png/img-totem_pro_works.png" alt="Tótem Pro Graphics" class="machine-logo">
                                    <img v-if="product_sku_data.machine_name === 'Tótem Pro Graphics'" src="../assets/media/png/img-totem_pro_graphics.png" alt="Tótem Pro Graphics" class="machine-logo">
                                    <!-- <p><strong>No. modelo:</strong></p> -->
                                    <p><strong>Model #:</strong></p>
                                    <p>{{product_sku_data.sku}}</p>
                                </div>
                                
                                <div class="col-8 machine-specs">

                                    <h5>{{machine_model}}</h5>
                                    <!-- <p>Modelo</p> -->
                                    <p>Model Name</p>
                                    
                                    <h6>{{product_sku_data.machine_cpu}}</h6>
                                    <!-- <p>Procesador (CPU)</p> -->
                                    <p>Processor (CPU)</p>

                                    <h6>{{product_sku_data.machine_ram}} GB</h6>
                                    <!-- <p>Memoria (RAM)</p> -->
                                    <p>Memory (RAM)</p>

                                    <h6>{{product_sku_data.machine_storage}} GB</h6>
                                    <!-- <p>Almacenamiento</p> -->
                                    <p>Storage</p>

                                    <h6>{{product_sku_data.machine_gpu}}</h6>
                                    <!-- <p>Procesador gráfico (GPU)</p> -->
                                    <p>Graphics Processor (GPU)</p>

                                    <h6>{{product_sku_data.machine_os}}</h6>
                                    <!-- <p>Sistema operativo</p> -->
                                    <p>Operating System</p>

                                    <h6>{{product_sku_data.machine_provider}}</h6>
                                    <!-- <p>Proveedor</p> -->
                                    <p>Provider</p>

                                    <h6>{{product_sku_data.machine_location}}</h6>
                                    <!-- <p>Ubicación</p> -->
                                    <p>Server location</p>

                                    <!-- <h6 v-if="product_sku_data.plan_billing_period === 'Monthly'">Mensual</h6> -->
                                    <h6 v-if="product_sku_data.plan_billing_period === 'Monthly'">Monthly</h6>
                                    <h6 v-else>{{product_sku_data.plan_billing_period}}</h6>
                                    <!-- <p>Tipo de plan</p> -->
                                    <p>Plan Type</p>

                                    <!-- <h6>{{product_sku_data.plan_hours}} horas</h6> -->
                                    <h6>{{product_sku_data.plan_hours}} hours</h6>
                                    <!-- <p>Tiempo del plan</p> -->
                                    <p>Plan Time</p>

                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="eraseMachineForDetails()">Cerrar</button>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- END Modal Machine Details -->

        <!-- START Modal Rename Machine -->
        <div class="modal fade" id="ModalMachineRename" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <!-- <h5 class="modal-title" id="exampleModalLabel">Cambiar nombre</h5> -->
                        <h5 class="modal-title" id="exampleModalLabel">Rename</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <form>
                            <div class="mb-3">
                                <!-- <input type="text" class="form-control mb-3" id="machine-name-edit" aria-describedby="textHelp" v-model="machine_alias_for_updating">
                                <div v-if="show_wrong_machine_alias_change" class="wrong_machine_alias_change">{{wrong_machine_alias_change}}</div>
                                <button type="button" class="btn btn-primary btn-sm" @click="changeMachineNameAlias(machine.machine_id, machine_alias_for_updating)">Guardar</button> -->
                                
                                <!-- <label for="machine-name-edit" class="form-label">Escribe un nuevo nombre para esta Cloud PC</label> -->
                                <label for="machine-name-edit" class="form-label">Choose a new name for this Cloud PC</label>

                                <input type="text" class="form-control" aria-describedby="textHelp" v-model="machine_alias_for_updating_2">
                                <div v-if="show_wrong_machine_alias_change_2" class="wrong_machine_alias_change">{{wrong_machine_alias_change}}</div>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button> -->
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <!-- <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="changeFromNavBarMachineAlias(machine.machine_id, machine_alias_for_updating_2)">Guardar</button> -->
                        <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="changeFromNavBarMachineAlias(machine.machine_id, machine_alias_for_updating_2)">OK</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- END Modal Machine Rename -->

        <!-- START Modal Machine Power Options -->
        <div class="modal fade" id="ModalMachinePower" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <!-- <h5 class="modal-title" id="exampleModalLabel">Apagar o reiniciar</h5> -->
                        <h5 class="modal-title" id="exampleModalLabel">Power Off</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="d-grid gap-2">
                            <button id="turnOffMachineButton" class="btn btn-danger" type="button" @click="turnOffMachine()" data-bs-dismiss="modal"><span class="bi-power">&nbsp;</span>Power Off</button>
                            <!-- <button id="resetMachineButton" class="btn btn-danger" type="button" @click="resetMachine()" data-bs-dismiss="modal"><span class="bi-arrow-clockwise">&nbsp;</span>Reiniciar</button> -->
                            <!-- <button class="btn btn-danger" type="button"><span class="bi-arrow-clockwise">&nbsp;</span>Reiniciar</button> -->
                        </div>
                    </div>
                    <div class="modal-footer">
                        <!-- <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Cancelar</button> -->
                        <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- END Modal Machine Power Options -->

        <!-- START Modal Connection Quality -->
        <!-- PENDING -->
        <!-- END Modal Connection Quality -->

        <!-- START OPTIONS OFFCANVAS -->
        <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasSettings" aria-labelledby="offcanvasRightLabel">
            
            <div class="offcanvas-header">
                <!-- <h5 id="offcanvasRightLabel"><span class="bi-gear-wide-connected">&nbsp;</span>Ajustes</h5> -->
                <h5 id="offcanvasRightLabel"><span class="bi-gear-wide-connected">&nbsp;</span>Options</h5>
                <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>

            <div class="offcanvas-body">
                <div class="accordion" id="accordionExample">

                    <div class="accordion-item">
                        
                        <h2 class="accordion-header" id="headingOne">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <!-- <span class="bi-sliders">&nbsp;</span>Generales -->
                                <span class="bi-sliders">&nbsp;</span>General
                            </button>
                        </h2>

                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                
                                <div class="settings-card">
                                    <!-- <h1>Nombre de tu Cloud PC</h1> -->
                                    <h1>Rename this Cloud PC</h1>
                                    <!-- <form class="mt-3"> -->
                                        <!-- <input type="text" class="form-control mb-3" id="machine-name-edit" aria-describedby="textHelp" value="Diseño"> -->
                                        <!-- <input type="text" class="form-control mb-3" id="machine-name-edit" aria-describedby="textHelp" v-model="machine.machine_alias"> -->
                                        <input type="text" class="form-control mb-3" id="machine-name-edit" aria-describedby="textHelp" v-model="machine_alias_for_updating">
                                        <div v-if="show_wrong_machine_alias_change" class="wrong_machine_alias_change">{{wrong_machine_alias_change}}</div>
                                        <!-- <button type="button" class="btn btn-primary btn-sm" @click="changeMachineNameAlias(machine.machine_id, machine_alias_for_updating)" :disabled="isActiveChangeMachineNameButton">Guardar</button> -->
                                        <button type="button" class="btn btn-primary btn-sm" @click="changeMachineNameAlias(machine.machine_id, machine_alias_for_updating)" :disabled="isActiveChangeMachineNameButton">OK</button>
                                    <!-- </form> -->
                                </div>
                                
                                <div class="settings-card">
                                    <div class="form-check form-switch mb-2">
                                        <input disabled class="form-check-input" type="checkbox" id="flexSwitchCheckDefault">
                                        <!-- <label class="form-check-label" for="flexSwitchCheckDefault">Apagado automático</label> -->
                                        <label class="form-check-label" for="flexSwitchCheckDefault">Auto-shutdown</label>
                                    </div>
                                    <!-- <p>Escoge el intervalo de tiempo que se podrá quedar encendida tu Cloud PC antes de apagarse cuando no estés interactuando con ella.</p> -->
                                    <p>Choose the length of time your Cloud PC can stay on before shutting down when you're not interacting with it.</p>
                                    <form class="mt-3">
                                        <select disabled class="form-select mb-3" aria-label="Default select example">
                                            <!-- <option value="1">30 minutos</option>
                                            <option value="2">1 hora</option>
                                            <option value="3">4 horas</option>
                                            <option value="4" selected>8 horas</option>
                                            <option value="5">24 horas</option> -->
                                            <option value="1">30 minutes</option>
                                            <option value="2">1 hour</option>
                                            <option value="3">4 hours</option>
                                            <option value="4" selected>8 hours</option>
                                            <option value="5">24 hours</option>
                                        </select>
                                        <!-- <button disabled type="submit" class="btn btn-primary btn-sm">Guardar</button> -->
                                        <button disabled type="submit" class="btn btn-primary btn-sm">OK</button>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingTwo">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                <!-- <span class="bi-cpu-fill">&nbsp;</span>Administración -->
                                <span class="bi-cpu-fill">&nbsp;</span>Manage
                            </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                            data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <!-- <p>Próximamente tendremos más opciones aquí.</p>
                                <p>Por el momento, puedes administrar tu Cloud PC y suscripción en tu cuenta</p> -->
                                <p>Soon we will have more options here.</p>
                                <p>You can manage your Cloud PC and subscription options at the store</p>
                                <!-- <button @click="openMyAccount()" type="button" class="btn btn-primary user-manual-btn"><span class="bi-box-arrow-up-right">&nbsp;</span>Ir a mi cuenta</button> -->
                                <button @click="openMyAccount()" type="button" class="btn btn-primary user-manual-btn"><span class="bi-box-arrow-up-right">&nbsp;</span>My Account</button>
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingThree">
                            <!-- <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree"> <span class="bi-card-checklist">&nbsp;</span>Suscripción</button> -->
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree"> <span class="bi-card-checklist">&nbsp;</span>Subscription</button>
                        </h2>
                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <!-- <p>Próximamente tendremos más opciones aquí.</p>
                                <p>Por el momento, puedes administrar tu Cloud PC y suscripción en tu cuenta</p>
                                <button @click="openMyAccount()" type="button" class="btn btn-primary user-manual-btn"><span class="bi-box-arrow-up-right">&nbsp;</span>Ir a mi cuenta</button> -->
                                <p>Soon we will have more options here.</p>
                                <p>You can manage your Cloud PC and subscription options at the store</p>
                                <button @click="openMyAccount()" type="button" class="btn btn-primary user-manual-btn"><span class="bi-box-arrow-up-right">&nbsp;</span>My account</button>
                            </div>
                        </div>
                    </div>
                
                </div>

            </div>

        </div>
        <!-- END OPTIONS OFFCANVAS -->
    
  </div>
</template>

<script>

import axios from 'axios'

import Navbar from '../components_modular/Navbar.vue'
import Footer from '../components_modular/Footer.vue'
import PinkBackground from '../components_modular/PinkBackground.vue'
// import io from 'socket.io-client';
import Toast from '../components_modular/Toast.vue'

export default {
    name: 'Machine',
    data () {
        return {

            show_wrong_machine_alias_change: false,
            show_wrong_machine_alias_change_2: false,
            
            // wrong_machine_alias_change: 'El nombre de la máquina no puede ser vacío.',
            wrong_machine_alias_change: 'The machine name cannot be empty.',
            
            name: null,
            email: null,
            machine: {
                name: 'Sin nombre',
                state: 'Sin estado'
            },

            hours: '00',
            minutes: '00',

            session_hours: '00',
            session_minutes: '00',

            anydesk: '',
            machine_name: '',
            machine_model: '',
            any_desk: '',
            first_machine_control_button_image_url: './svg/icn-power.svg',

            is_mounted_navbar: false,
            is_mounted: false,

            remaining_hours: null,
            remaining_minutes: null,
            
            machine_alias_for_updating: '',
            machine_alias_for_updating_2: '',

            isActiveChangeMachineNameButton: false,

            is_first_button_pressed_to_on: false,
            is_first_button_pressed_to_off: false,
            
            disable_back_button: false,

            product_sku_data: {},

            deactivate_machine_controls: false,

            negative_hours: false,

            toastVisible: false,
            toastTitle: 'Toast Title',
            toastMessage: 'This is a Bootstrap toast!',
            
        }
    },
    components: {
        Navbar,
        Footer,
        PinkBackground,
        Toast,
    },
    beforeMount () {

        // this.getMachineInformation();

    },
    mounted () {

        /*
        this.socket = io('http://localhost:8082');
        this.socket.on('databaseChange', (data) => {
            console.log(data);
        });
        this.socket.on('databaseChange', this.handleDatabaseChange);
        */

        this.getMachineLiveState();
        this.getMachineSessionUtilization();
        this.getMachineProductSkuData();
        
        window.setInterval(() => {
            if(
                this.$parent.machine_id !== null &&
                this.$parent.machine_id !== 'null' &&
                this.$parent.machine_id !== "null" &&
                this.$parent.machine_id !== undefined &&
                this.$parent.machine_id !== 'undefined' &&
                this.$parent.machine_id !== "undefined"
            ){
                this.getMachineLiveState();
                this.getMachineSessionUtilization();
            }
        }, 40000);

    },
    methods : {

        showToast() {
            this.toastVisible = true;
        },
        hideToast() {
            this.toastVisible = false;
        },

        handleDatabaseChange(change) {
            // Update the databaseChanges array with the new change
            console.log(change);
        },

        getMachineProductSkuData () {

            let machine_id = this.$parent.machine_id;

            let url2 = this.$parent.server_url + '/api/own/' + machine_id + '/get_machine_product_sku_data'

            axios.get(url2)
            .then(response2 => {

                this.machine_model = response2.data.product_sku_data.machine_name;
                
                // this.machine['product_sku_data'] = response2.data.product_sku_data;
                this.product_sku_data = response2.data.product_sku_data;

                // SE DEJÓ ESTO PARA SABER SI NO ENTRA EN CONFLICTO CON QUE SE HAYA DESACOPLADO /INFORMATION /SKU_DATA Y /STATE DE getMachineInformation()

                // let url3 = this.$parent.server_url + '/api/own/' + machine_id + '/get_machine_live_state'
                // axios.get(url3)
                // .then(response3 => {
                //     this.machine.state = response3.data.machine_state;
                    // this.is_mounted_navbar = true;
                    this.is_mounted = true;
                // })
                // .catch(function(error3) {
                //     console.log(error3);
                // })

            })
            .catch(function(error2) {
                console.log(error2);
            })

        },

        changeFromNavBarMachineAlias (machineId, machineAlias) {

            if(
                machineAlias === '' ||
                machineAlias === ""
            ){
                
                this.show_wrong_machine_alias_change_2 = true;

            }else{

                this.show_wrong_machine_alias_change_2 = false;

                // console.log(machineId);
                // console.log(machineAlias);
                
                let url = this.$parent.server_url + '/api/own/' + machineId + '/change_machine_name/' + machineAlias;

                axios.get(url)
                .then(response => {

                    // console.log(response);
                    console.log(response.data);

                    // this.getMachineInformation();
                    
                    this.machine_alias_for_updating = machineAlias;
                    this.machine_alias_for_updating_2 = machineAlias;
                    
                    this.machine_name = machineAlias;

                    this.machine.machine_alias = machineAlias;

                })
                .catch(function(error) {
                    console.log(error);
                })

            }

        },

        changeMachineNameAlias(machineId, machineAlias){

            this.isActiveChangeMachineNameButton = true;

            if(
                machineAlias === '' ||
                machineAlias === ""
            ){
                
                this.show_wrong_machine_alias_change = true;

            }else{

                this.show_wrong_machine_alias_change = false;

                // console.log(machineId);
                // console.log(machineAlias);
                
                let url = this.$parent.server_url + '/api/own/' + machineId + '/change_machine_name/' + machineAlias;

                axios.get(url)
                .then(response => {

                    // console.log(response);
                    console.log(response.data);

                    // this.getMachineInformation();

                    this.machine_alias_for_updating = machineAlias;
                    this.machine_alias_for_updating_2 = machineAlias;

                    this.machine_name = machineAlias;

                    this.machine.machine_alias = machineAlias;

                    this.isActiveChangeMachineNameButton = false;

                })
                .catch(function(error) {

                    console.log(error);

                    this.isActiveChangeMachineNameButton = false;

                })

            }

        },

        renewSubscription () {

            window.open('https://totem.computer/my-account/view-subscription/' + this.machine.subscription_id + '/', '_blank');

        },

        blockActionButtonWhenTurningOn () {

            this.first_machine_control_button_image_url = './gif/loader.gif';
            
            // URLS DE LAS IMAGENES PARA EL DINAMISMO DEL BOTON?

            // DISABLE TURN ON MACHINE LINK
            // DISABLE TURN OFF MACHINE BUTTON
            // ADD DISABLE CSS CLASS TO TURN ON/OFF MACHINE CONTROL BUTTON
            // ADD DISABLE CSS CLASS TO RED TURN OFF MACHINE BUTTON => CREO QUE NO

            // ENABLE TURN ON MACHINE LINK
            // ENABLE TURN OFF MACHINE BUTTON
            // REMOVE DISABLE CSS CLASS TO TURN ON/OFF MACHINE CONTROL BUTTON
            // REMOVE DISABLE CSS CLASS TO RED TURN OFF MACHINE BUTTON => CREO QUE NO

            document.getElementById("turnOnMachineATag").classList.add("a-tag-disabled");
            document.getElementById("turnOffMachineButton").disabled = true;

            document.getElementById("firstMachineControlButton").classList.add("action-button-disabled");
            
            // setTimeout(function(){

            //     this.first_machine_control_button_image_url = './icn-power.svg';

            // },15000);

            setTimeout(function(){

                // document.getElementById("turnOnMachineATag").classList.remove("a-tag-disabled");
                document.getElementById("turnOffMachineButton").disabled = false;

                document.getElementById("firstMachineControlButton").classList.remove("action-button-disabled");

            }, 30000);

        },

        blockActionButtonWhenTurningOff () {

            this.first_machine_control_button_image_url = './gif/loader.gif';

            // document.getElementById("secondMachineControlButton").classList.add("action-button-disabled");
            // document.getElementById("linkForConnectingToAnyDesk").classList.add("a-tag-disabled");

            // URLS DE LAS IMAGENES PARA EL DINAMISMO DEL BOTON?

            // DISABLE TURN ON MACHINE LINK
            // DISABLE TURN OFF MACHINE BUTTON
            // ADD DISABLE CSS CLASS TO TURN ON/OFF MACHINE CONTROL BUTTON
            // ADD DISABLE CSS CLASS TO RED TURN OFF MACHINE BUTTON => CREO QUE NO

            // ENABLE TURN ON MACHINE LINK
            // ENABLE TURN OFF MACHINE BUTTON
            // REMOVE DISABLE CSS CLASS TO TURN ON/OFF MACHINE CONTROL BUTTON
            // REMOVE DISABLE CSS CLASS TO RED TURN OFF MACHINE BUTTON => CREO QUE NO

            document.getElementById("turnOffMachineATag").classList.add("a-tag-disabled");
            document.getElementById("turnOffMachineButton").disabled = true;

            document.getElementById("firstMachineControlButton").classList.add("action-button-disabled");

            // setTimeout(function(){

            //     this.first_machine_control_button_image_url = './icn-power.svg';

            // },15000);

            setTimeout(function(){

                // document.getElementById("secondMachineControlButton").classList.remove("action-button-disabled");
                // document.getElementById("linkForConnectingToAnyDesk").classList.remove("a-tag-disabled");

                // document.getElementById("turnOffMachineATag").classList.remove("a-tag-disabled");
                document.getElementById("turnOffMachineButton").disabled = false;

                document.getElementById("firstMachineControlButton").classList.remove("action-button-disabled");

            }, 30000);

        },

        goBackToHome(){
            
            this.$parent.component = 'Home';

        },

        getMachineNameAndModel(){

            let url = this.$parent.server_url + '/api/own/' + this.$parent.machine_id + '/get_machine_model_name';

            console.log(url);

            axios.get(url)
            .then(response => {

                console.log(response);

                this.machine_name = response.data.paperspace;
                this.machine_model = response.data.woocommerce;
                
            })
            .catch(function(error) {
                console.log(error);
            })

        },

        getMachineAnyDesk(){

            let url = this.$parent.server_url + '/api/own/' + this.$parent.machine_id + '/get_machine_any_desk';

            console.log(url);

            axios.get(url)
            .then(response => {

                console.log(response);

                this.any_desk = response.data.anydesk;
                
            })
            .catch(function(error) {
                console.log(error);
            })

        },

        getMachineInformation () {

            // console.clear();

            let machine_id = this.$parent.machine_id;

            let url = this.$parent.server_url + '/api/paperspace/' + machine_id + '/get_machine_information';

            axios.get(url)
            .then(response => {

                console.log('----- ----- ----- INICIO ----- ----- -----');
                console.log(new Date().toISOString().substring(0,19).replace('T',' '));

                this.machine = response.data.machine;

                let temporal_avail_time = this.machine.avail_time;
                let temporal_available_hours = '';
                let temporal_available_minutes = '';

                console.log('[0.0] temporal_avail_time: ' + temporal_avail_time);
                console.log('[0.0] machine_state: ' + response.data.machine.state);
                
                if(response.data.machine.state === 'ready' || response.data.machine.state === 'serviceready' || response.data.machine.state === 'restarting'){

                    temporal_avail_time = temporal_avail_time - this.session_hours;
                    temporal_avail_time = temporal_avail_time - ((this.session_minutes * 1.67796610169492) / 100);
                    temporal_avail_time = temporal_avail_time.toFixed(2);

                    console.log('[1.1] temporal_avail_time: ' + temporal_avail_time);

                    temporal_available_hours = parseInt(temporal_avail_time);
                    temporal_available_minutes = parseInt((parseInt(String(temporal_avail_time).split('.')[1]) * 60) / 100);

                    console.log('[1.1] temporal_available_hours: ' + temporal_available_hours);
                    console.log('[1.1] temporal_available_minutes: ' + temporal_available_minutes);

                }else{

                    temporal_available_hours = parseInt(temporal_avail_time);
                    temporal_available_minutes = parseInt((parseInt(String(temporal_avail_time).split('.')[1]) * 60) / 100);

                    console.log('[1.2] temporal_available_hours: ' + temporal_available_hours);
                    console.log('[1.2] temporal_available_minutes: ' + temporal_available_minutes);

                }
                
                console.log('[2.0] is_in_grace_period: ' + this.machine.is_in_grace_period);

                if(temporal_available_hours <= 0){
                    if(temporal_available_minutes == 30){
                        this.toastVisible = true;
                        this.toastTitle = 'Time';
                        this.toastMessage = 'There are 30 minutes of time left.';
                    }else if(temporal_available_minutes == 10){
                        this.toastVisible = true;
                        this.toastTitle = 'Time';
                        this.toastMessage = 'There are 10 minutes of time left.';
                    }else if(temporal_available_minutes == 3){
                        this.toastVisible = true;
                        this.toastTitle = 'Time';
                        this.toastMessage = 'There are 3 minutes of time left.';
                    }else if(temporal_available_minutes == 1){
                        this.toastVisible = true;
                        this.toastTitle = 'Time';
                        this.toastMessage = '1 minute left of time.';
                    } 
                }
                
                // console.log(typeof temporal_available_hours);
                // console.log(typeof temporal_available_minutes);
                // console.log(temporal_available_hours <= 0);
                // console.log(temporal_available_minutes <= 0);
                // console.log(temporal_available_hours <= 0 && temporal_available_minutes <= 0);

                if(this.machine.is_in_grace_period === true || this.machine.is_in_grace_period === 1){

                    this.deactivate_machine_controls = true;
                    
                    console.log('[2.1] Se desactivaron los machine controls');
                    
                    if(response.data.machine.state === 'ready' || response.data.machine.state === 'serviceready'){
                        this.turnOffMachine();
                        console.log('[2.1] Se mandó a apagar la máquina');
                    }
                    
                }else if(temporal_available_hours <= 0 && temporal_available_minutes <= 0){

                    this.deactivate_machine_controls = true;
                    console.log('[2.2] Se desactivaron los machine controls');

                    if(response.data.machine.state === 'ready' || response.data.machine.state === 'serviceready'){
                        this.turnOffMachine();
                        console.log('[2.2] Se mandó a apagar la máquina');
                    }

                }else{    
                    this.deactivate_machine_controls = false;
                    console.log('[2.3] No se desactivaron los machine controls');
                }

                if(temporal_available_minutes < 10){
                    temporal_available_minutes = '0' + String(temporal_available_minutes);
                    console.log('[3.0] Se le agregó un 0 a los minutos disponibles');
                }
                
                this.machine.avail_time_0 = temporal_available_hours;
                this.machine.avail_time_1 = temporal_available_minutes;
                this.machine_alias_for_updating = this.machine.machine_alias;
                this.machine_alias_for_updating_2 = this.machine.machine_alias;
                this.any_desk = this.machine.rdp_link;
                this.machine_name = this.machine.machine_alias;

                if(String(temporal_avail_time).split('.')[0].includes("-")){
                    if(
                        parseInt(String(temporal_avail_time).split('.')[0]) == 0 || 
                        parseInt(String(temporal_avail_time).split('.')[0]) === 0 || 
                        String(temporal_avail_time).split('.')[0] == '-0' || 
                        String(temporal_avail_time).split('.')[0] === "-0"
                        ){
                            console.log('[4.0] Hay signo negativo.');
                            this.deactivate_machine_controls = true;
                            this.negative_hours = true;
                    }else{
                        console.log('[4.1] No hay signo negativo.');
                        this.negative_hours = false;
                    }
                }else{
                    console.log('[4.2] No hay signo negativo.');
                    this.negative_hours = false;
                }

                console.log('----- ----- ----- FIN ----- ----- -----');
                
            })
            .catch(function(error) {
                console.log(error);
            })
            
        },
        
        getMachineSessionUtilization () {
            
            let machine_id = this.$parent.machine_id;

            let url = this.$parent.server_url + '/api/paperspace/' + machine_id + '/get_session_utilization';

            // console.log(url);

            axios.get(url)
            .then(response => {

                // console.log(response);
                // console.log(response.data);
                
                this.session_hours = response.data.hours;
                this.session_minutes = response.data.minutes;

                console.log('----- ----- ----- INICIO ----- ----- -----');
                console.log(new Date().toISOString().substring(0,19).replace('T',' '));

                this.machine = response.data.machine;

                let temporal_avail_time = this.machine.avail_time;
                let temporal_available_hours = '';
                let temporal_available_minutes = '';

                console.log('[0.0] temporal_avail_time: ' + temporal_avail_time);
                console.log('[0.0] machine_state: ' + response.data.machine.state);
                
                if(response.data.machine.state === 'ready' || response.data.machine.state === 'serviceready' || response.data.machine.state === 'restarting'){

                    temporal_avail_time = temporal_avail_time - this.session_hours;
                    temporal_avail_time = temporal_avail_time - ((this.session_minutes * 1.67796610169492) / 100);
                    temporal_avail_time = temporal_avail_time.toFixed(2);

                    console.log('[1.1] temporal_avail_time: ' + temporal_avail_time);

                    temporal_available_hours = parseInt(temporal_avail_time);
                    temporal_available_minutes = parseInt((parseInt(String(temporal_avail_time).split('.')[1]) * 60) / 100);

                    console.log('[1.1] temporal_available_hours: ' + temporal_available_hours);
                    console.log('[1.1] temporal_available_minutes: ' + temporal_available_minutes);

                }else{

                    temporal_available_hours = parseInt(temporal_avail_time);
                    temporal_available_minutes = parseInt((parseInt(String(temporal_avail_time).split('.')[1]) * 60) / 100);

                    console.log('[1.2] temporal_available_hours: ' + temporal_available_hours);
                    console.log('[1.2] temporal_available_minutes: ' + temporal_available_minutes);

                }
                
                console.log('[2.0] is_in_grace_period: ' + this.machine.is_in_grace_period);

                if(temporal_available_hours <= 0){
                    if(temporal_available_minutes == 30){
                        this.toastVisible = true;
                        this.toastTitle = 'Time';
                        this.toastMessage = 'There are 30 minutes of time left.';
                    }else if(temporal_available_minutes == 10){
                        this.toastVisible = true;
                        this.toastTitle = 'Time';
                        this.toastMessage = 'There are 10 minutes of time left.';
                    }else if(temporal_available_minutes == 3){
                        this.toastVisible = true;
                        this.toastTitle = 'Time';
                        this.toastMessage = 'There are 3 minutes of time left.';
                    }else if(temporal_available_minutes == 1){
                        this.toastVisible = true;
                        this.toastTitle = 'Time';
                        this.toastMessage = '1 minute left of time.';
                    } 
                }
                
                // console.log(typeof temporal_available_hours);
                // console.log(typeof temporal_available_minutes);
                // console.log(temporal_available_hours <= 0);
                // console.log(temporal_available_minutes <= 0);
                // console.log(temporal_available_hours <= 0 && temporal_available_minutes <= 0);

                if(this.machine.is_in_grace_period === true || this.machine.is_in_grace_period === 1){

                    this.deactivate_machine_controls = true;
                    
                    console.log('[2.1] Se desactivaron los machine controls');
                    
                    if(response.data.machine.state === 'ready' || response.data.machine.state === 'serviceready'){
                        this.turnOffMachine();
                        console.log('[2.1] Se mandó a apagar la máquina');
                    }
                    
                }else if(temporal_available_hours <= 0 && temporal_available_minutes <= 0){

                    this.deactivate_machine_controls = true;
                    console.log('[2.2] Se desactivaron los machine controls');

                    if(response.data.machine.state === 'ready' || response.data.machine.state === 'serviceready'){
                        this.turnOffMachine();
                        console.log('[2.2] Se mandó a apagar la máquina');
                    }

                }else{    
                    this.deactivate_machine_controls = false;
                    console.log('[2.3] No se desactivaron los machine controls');
                }

                if(temporal_available_minutes < 10){
                    temporal_available_minutes = '0' + String(temporal_available_minutes);
                    console.log('[3.0] Se le agregó un 0 a los minutos disponibles');
                }
                
                this.machine.avail_time_0 = temporal_available_hours;
                this.machine.avail_time_1 = temporal_available_minutes;
                this.machine_alias_for_updating = this.machine.machine_alias;
                this.machine_alias_for_updating_2 = this.machine.machine_alias;
                this.any_desk = this.machine.rdp_link;
                this.machine_name = this.machine.machine_alias;

                if(String(temporal_avail_time).split('.')[0].includes("-")){
                    if(
                        parseInt(String(temporal_avail_time).split('.')[0]) == 0 || 
                        parseInt(String(temporal_avail_time).split('.')[0]) === 0 || 
                        String(temporal_avail_time).split('.')[0] == '-0' || 
                        String(temporal_avail_time).split('.')[0] === "-0"
                        ){
                            console.log('[4.0] Hay signo negativo.');
                            this.deactivate_machine_controls = true;
                            this.negative_hours = true;
                    }else{
                        console.log('[4.1] No hay signo negativo.');
                        this.negative_hours = false;
                    }
                }else{
                    console.log('[4.2] No hay signo negativo.');
                    this.negative_hours = false;
                }

                console.log('----- ----- ----- FIN ----- ----- -----');

            });
            
        },

        getMachineUtilization () {

            // console.log(this.name);
            // console.log(this.email);
            // console.log(this.machines_amount);
            // console.log(this.machines);

            let machine_id = this.$parent.machine_id;

            let url = this.$parent.server_url + '/api/paperspace/' + machine_id + '/get_machine_utlization';

            // console.log(url);

            axios.get(url)
            .then(response => {

                // console.log(response);
                // console.log(response.data);
                
                this.hours = response.data.hours;
                this.minutes = response.data.minutes;

                // PENDIETE
                // SI LAS HORAS SON 50 + PERIODO DE GRACIA REGRESAR => APAGAR LA MAQUINA Y REGRESAR A HOME

                // let hours = Number(response.data.hours);

                // TIEMPO DISPONIBLE CORRECTO --
                // remaining_hours
                // remaining_minutes
                // console.log(this.machine.product_sku_data.user_time_hrs);
                // console.log(this.hours);
                // console.log(this.minutes);

                var temporal_hours = parseInt(this.hours);
                var temporal_minutes = parseInt(this.minutes);

                // console.log(temporal_hours);
                // console.log(temporal_minutes);

                if(temporal_minutes >= 1){

                    // temporal_hours = temporal_hours - 1;
                    temporal_minutes = 60 - temporal_minutes;

                    // console.log(temporal_hours);
                    // console.log(temporal_minutes);

                    this.remaining_hours = parseInt(this.machine.plan_time) - temporal_hours - 1;
                    this.remaining_minutes = temporal_minutes;

                    // console.log(this.remaining_hours)
                    // console.log(this.remaining_minutes)

                    if(this.remaining_hours <= 9){

                        this.remaining_hours = '0' + this.remaining_hours;

                    }

                    // if(this.temporal_minutes <= 9){

                    //     this.remaining_minutes = '0' + this.temporal_minutes;

                    // }

                    if(this.remaining_minutes <= 9){

                        this.remaining_minutes = '0' + this.remaining_minutes;

                    }

                }else{

                    this.remaining_hours = this.machine.plan_time - temporal_hours;

                    if(this.remaining_hours <= 9){

                        this.remaining_hours = '0' + this.remaining_hours;

                    }

                    this.remaining_minutes = '00';

                }

                // ----- ----- ----- ----- -----

                // if(this.hours > this.machine.plan_time){
                //     this.turnOffMachine();
                //     this.$parent.component = 'Home';
                // }
                
            })
            .catch(function(error) {
                console.log(error);
            })

        },

        turnOnMachine () {

            this.disable_back_button = true;

            if(this.is_first_button_pressed_to_off === true){

                this.is_first_button_pressed_to_off = false;

            }

            if(this.is_first_button_pressed_to_on === false){

                this.is_first_button_pressed_to_on = true;

            }

            // /api/paperspace/plain/start/:machine_id

            let url = this.$parent.server_url + '/api/paperspace/plain/start/' + this.$parent.machine_id;

            // console.log(url);

            axios.get(url)
            .then(response => {

                // console.log(response);
                console.log(response.data);
                
                // this.is_mounted = false;
                // this.getMachineInformation();

                // this.getMachineUtilization();
                
            })
            .catch(function(error) {
                console.log(error);
            })

            // this.blockActionButtonWhenTurningOn();

        },

        justTurnOffMachine () {

            this.disable_back_button = true;

            if(this.is_first_button_pressed_to_on === true){

                this.is_first_button_pressed_to_on = false;

            }

            if(this.is_first_button_pressed_to_off === false){

                this.is_first_button_pressed_to_off = true;

            }

            // /api/paperspace/plain/start/:machine_id

            let url = this.$parent.server_url + '/api/paperspace/plain/just/stop/' + this.$parent.machine_id;

            console.log(url);

            axios.get(url)
            .then(response => {

                console.log(response);
                console.log(response.data);
                
                // this.is_mounted = false;
                // this.getMachineInformation();

                // this.getMachineUtilization();
                
            })
            .catch(function(error) {
                console.log(error);
            })

            // this.blockActionButtonWhenTurningOff();

        },

        turnOffMachine () {

            this.disable_back_button = true;

            if(this.is_first_button_pressed_to_on === true){

                this.is_first_button_pressed_to_on = false;

            }

            if(this.is_first_button_pressed_to_off === false){

                this.is_first_button_pressed_to_off = true;

            }

            // /api/paperspace/plain/start/:machine_id

            let url = this.$parent.server_url + '/api/paperspace/plain/stop/' + this.$parent.machine_id;

            console.log(url);

            axios.get(url)
            .then(response => {

                // console.log(response);
                console.log(response.data);
                
                // this.is_mounted = false;
                // this.getMachineInformation();

                // this.getMachineUtilization();
                
            })
            .catch(function(error) {
                console.log(error);
            })

            // this.blockActionButtonWhenTurningOff();

        },

        resetMachine(){

             // /api/paperspace/plain/start/:machine_id

            let url = this.$parent.server_url + '/api/paperspace/plain/reset/' + this.$parent.machine_id;

            console.log(url);

            axios.get(url)
            .then(response => {

                console.log(response);
                console.log(response.data);
                
                this.is_mounted = false;
                this.getMachineInformation();

                // this.getMachineUtilization();
                
            })
            .catch(function(error) {
                console.log(error);
            })

            this.blockActionButtonWhenTurningOff();

        },

        openMyAccount () {

            window.open(this.$parent.my_account, '_blank');

        },

        eraseMachineForDetails(){

            this.machine_selected = false;
            this.selected_machine_for_details = null;

        },

        getMachineLiveState(){

            let machine_id = this.$parent.machine_id;
            
            let url = this.$parent.server_url + '/api/own/' + machine_id + '/get_machine_live_state'

            let old_machine_state = this.machine.state;

            axios.get(url)
            .then(response => {

                let now = new Date().toISOString().substring(0,19).replace('T',' ');

                console.log('this.machine.state: ' + this.machine.state);
                console.log('response.data.machine_state: ' + response.data.machine_state);

                if((old_machine_state !== 'Sin estado' && old_machine_state !== 'ready') && response.data.machine_state === 'ready'){
                    console.log('[' + now + '][ready]: Apareció la notificación')
                    this.toastVisible = true;
                    this.toastTitle = 'Machine';
                    this.toastMessage = 'The machine has been turned on.';
                }else if((old_machine_state !== 'Sin estado' && old_machine_state !== 'off') && response.data.machine_state === 'off'){
                    console.log('[' + now + '][ off ]: Apareció la notificación')
                    this.toastVisible = true;
                    this.toastTitle = 'Machine';
                    this.toastMessage = 'The machine has been turned off.';
                }else{
                    console.log('[' + now + '][ ready/off ]: No apareció la notificación')
                }

                // DESACOPLADO DEL LLAMADO DE  getMachineInformation()
                this.machine.state = response.data.machine_state;
                this.is_mounted_navbar = true;
                // this.is_mounted = true;
                // ----- ----- ----- ----- ----- ----- ----- ----- -----

                // console.log('Found machine state from request response: ' + response.data.machine_state)

                // this.machine.state = response.data.machine_state;

                // console.log('Now the machine state in the application has the following state: ' + this.machine.state)


                if(this.machine.state === 'serviceready' || this.machine.state === 'ready'){

                    if(this.is_first_button_pressed_to_on === true){

                        this.is_first_button_pressed_to_on = false;
                        this.disable_back_button = false;

                    }else{

                        // Por si las dudas
                        this.is_first_button_pressed_to_on = false;
                        this.disable_back_button = false;

                    }

                }

                if(this.machine.state === 'off'){

                    if(this.is_first_button_pressed_to_off === true){

                        this.is_first_button_pressed_to_off = false;
                        this.disable_back_button = false;

                    }else{

                        // Por si las dudas
                        this.is_first_button_pressed_to_off = false;
                        this.disable_back_button = false;

                    }

                }

            })
            .catch(function(error) {
                console.log(error);
            })

        },

    }

}
</script>

<style scoped>
.wrong_machine_alias_change{
    color: red;
    padding-bottom: 15px;
}
</style>
