<template>
    <div>
        <!-- SKY BACKGROUND -->
        <video playsinline autoplay muted loop poster="polina.jpg" id="bgvid">
            <source src="../assets/media/mp4/video-blue_sky.mp4" type="video/mp4">
            <source src="../assets/media/webm/video-blue_sky.webm" type="video/webm">
        </video>
        <!-- SKY BACKGROUND END -->
        
    </div>
</template>

<script>
export default {
    name: 'SkyBackground',
    props: {
    },
    data () {
        return {
        }
    },
    components: {
    },
    mounted () {
    },
    methods : {
    }
}
</script>

<style scoped>

</style>
